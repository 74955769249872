import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container, Row, Col, Image} from 'react-bootstrap';
import logoFull from './logoFull.png';
import { createRequire } from 'module';

class Header extends React.Component{
    
    render(){

        return(
            <div>
                <Container fluid>
                    <Row style={headerStyle}> 
                        <Col>Protect3d</Col>
                        <Col>Orders</Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Header;

var headerStyle = {
    padding: '20px',
    background: 'lightgray',
    fontSize: '40px',
    fontFamily: 'Roboto'
}
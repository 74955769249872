import React from 'react';
import firebase from 'firebase';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Spinner} from 'react-bootstrap';
import OrderDetail from './Orders/OrderDetail';

import {getOrderDetail} from './Firebase';

import { connect, useStore, useSelector } from 'react-redux';
import { getFirestore } from 'redux-firestore';
import { setOrderListener } from '../actions/orderActions';


//const store = useStore();
const db = firebase.firestore();

class Detail extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            orderInfo: []
        }

        this.getOrderDetail = getOrderDetail.bind(this)

        let unsubRef;

        //this.unsubRef = this.getOrderDetail(this.props.location.state.orderInfo.ref);
    }

    stopListener = (unsubRef) => {
        unsubRef();
    }

    componentDidMount() {
        console.log(this.props)
        this.setCurrentOrderListener()
        
    }

    componentWillUnmount() {
        //this.stopListener(this.unsubRef)
        const firestore = getFirestore();
        const orderID = this.props.currentOrderID;


        firestore.unsetListener({
            collectionGroup: 'orders',
            where: ['padUniqueID', '==', orderID]
        });
        
    }
    
    render() {
        console.log('rendering Detail with this orderInfo')
        //console.log(this.state.orderInfo)
        console.log(this.props)

        const cur = this.props.currentOrder;

        console.log(cur)

        if (cur == null) {
            return <Spinner animation="border" variant="primary"/>
        } else {
            return (
                <div>
                    {this.state.isLoading &&
                    <h1>Loading...</h1>
                    }
                    <h1>Order Detail</h1>
                    <OrderDetail dataFromParent={cur[Object.keys(cur)[0]]}/>
                </div>
            ); 
        }
        
        //console.log(cur[Object.keys(cur)[0]]);
    }

    setCurrentOrderListener = () => {
        //const store = useStore();
        const firestore = getFirestore();

        console.log(this.props)
        const orderID = this.props.currentOrderID;
        
        //this.props.setCurrentOrderListener(orderID);

        console.log(orderID)

        

        firestore.setListener({
            collectionGroup: 'orders',
            where: ['padUniqueID', '==', orderID],
        })

        //console.log(firestore)
        
    }
}

const mapStateToProps = (state) => {
    console.log(state)
    return {
        currentOrderID: state.order.currentOrderID,
        currentOrder: state.firestore.data.orders
    }
}
const mapDispatchToProps = (dispatch) => {
    return {  
        setCurrentOrderListener: (orderID) => dispatch(setOrderListener(orderID)),
      }
}

export default connect(mapStateToProps, mapDispatchToProps)(Detail);
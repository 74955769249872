import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Navbar, Nav, Button} from 'react-bootstrap';

import firebase from 'firebase';

class Menu extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Navbar bg="light" expand="lg">
                <Navbar.Brand href="/home">Protect3d</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link href="/home">Home</Nav.Link>
                        <Nav.Link href="/orders">Orders</Nav.Link>
                        <Nav.Link href="/clients">Clients</Nav.Link>
                        <Nav.Link href="/manage">Manage</Nav.Link>
                    </Nav>

                    <Button variant="outline-dark" onClick={checkUser.bind(this)} >Check User</Button>
                    <Button variant="outline-danger" onClick={signOut.bind(this)}>Sign Out</Button>

                </Navbar.Collapse>
            
            </Navbar>
        )
    }

}

export default withRouter(Menu);

function checkUser(){
    console.log('func triggered');

    firebase.auth().onAuthStateChanged(function(user){
        if (user){
            console.log(user.email)
        } else{
            console.log('no user')
        }
    })
};

async function signOut(){
    console.log('func triggered');

    await firebase.auth().signOut();

    console.log('signed out')

    this.props.history.push('/');
}
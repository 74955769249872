import firebase from 'firebase';

export const fbConfig = {
    apiKey: "AIzaSyDNw1PsUcjFilHGXcqtJIMK9JSP5U0Bv48",
    authDomain: "protect3d.firebaseapp.com",
    databaseURL: "https://protect3d.firebaseio.com",
    projectId: "protect3d",
    storageBucket: "protect3d.appspot.com",
    messagingSenderId: "17791815794",
    appId: "1:17791815794:web:6d6a68c3ae7c6ceaa7f2eb",
    measurementId: "G-56NM895884"
};

firebase.initializeApp(fbConfig);

const db = firebase.firestore();

export default firebase;

// Get current user and info

export var getCurrentUser = function getCurrentUser() {
    console.log('getting current user')
    var userEmail

    firebase.auth().onAuthStateChanged(function(user){
        if (user){
            userEmail = user.email
        } else{
            console.log('no user')
        }
    })

    return userEmail;
}

// Get 'open' orders for Home page

export var getOpenOrders = function getOpenOrders() {
    const unsubscribe = db.collectionGroup('orders')
    .where('internalStatus', '<', '4')
    .onSnapshot(snapshot => {
        let orders = [];

        snapshot.forEach(doc => {
            var clientPath = doc.ref.parent.parent.parent.path.toString();
            var client = clientPath.split('/')[3];

            if((client != 'Protect3d')){
                orders.push({
                    name: doc.data().name,
                    number: doc.data().number,
                    client: client,
                    team: doc.data().team,
                    position: doc.data().position,
                    padType: doc.data().padType,
                    injuryDesc: doc.data().injuryDesc,
                    priority: doc.data().priority,
                    orderStatus: doc.data().orderStatus,
                    internalStatus: doc.data().internalStatus,
                    fileLink: doc.data().fileLink,
                    ref: doc.ref.path,
                    id: doc.id
                })
            }
        })

        this.setState({
            orders,
            loading: true,
        })
    })

    return unsubscribe;

}

// Get orders based on specified parameters for Orders page

export var getOrdersFromQuery = function getOrdersFromQuery() {
    
    const unsubscribe = db.collection('sectors').doc('athletics')
        .collection('clients').doc(this.state.selectedClient)
        .collection('teams').doc(this.state.selectedTeam)
        .collection('orders').onSnapshot(snapshot => {

        let orders = [];

        snapshot.forEach(doc => {

            var clientPath = doc.ref.parent.parent.parent.parent.path.toString();
            var client = clientPath.split('/')[3];

            orders.push({
                name: doc.data().name,
                number: doc.data().number,
                client: client,
                team: doc.data().team,
                position: doc.data().position,
                padType: doc.data().padType,
                injuryDesc: doc.data().injuryDesc,
                priority: doc.data().priority,
                orderStatus: doc.data().orderStatus,
                internalStatus: doc.data().internalStatus,
                fileLink: doc.data().fileLink,
                ref: doc.ref.path,
                id: doc.id,
                bodyPart: doc.data().bodyPart,
                designModificationComments: doc.data().designModificationComments,
                pair: doc.data().pair,
                reviewModel: doc.data().reviewModel,
                timelineComments: doc.data().timelineComments,
                timelinePriority: doc.data().timelinePriority,

                versionCount: doc.data().versionCount,
                modelingTimes: doc.data().modelingTimes,
                resinTypes: doc.data().resinTypes,
                resinAmounts: doc.data().resinAmounts,
                printCounts: doc.data().printCounts,

                invoiceNumber: doc.data().invoiceNumber,
                productRevenue: doc.data().productRevenue,
                shippingRevenue: doc.data().shippingRevenue,
                totalRevenue: doc.data().totalRevenue,
                materialCost: doc.data().materialCost,
                shippingCost: doc.data().shippingCost,
                totalCost: doc.data().totalCost,
                netProfit: doc.data().netProfit,
            })
        })

        this.setState({orders});
    })

    return unsubscribe;            
}

// Get all clients for Orders page

export var getClients = function getClients() {

    const unsubscribe = db.collection('sectors').doc('athletics')
        .collection('clients').onSnapshot(snapshot => {
            let clients = [];

            console.log(snapshot)

            snapshot.forEach(doc => {

                clients.push({
                    id: doc.id
                });
            });

            this.setState({clients});
        })
    console.log(unsubscribe)
    return unsubscribe;
}

// Get all client info for Clients page

export var getClientInfo = function getClientInfo() {

    const unsubscribe = db.collection('sectors').doc('athletics')
    .collection('clients').onSnapshot(async snapshot => {
        let clients = [];

        console.log(snapshot)

        await snapshot.forEach(async doc => {

            var clientID = doc.id
            var clientRef;
            var clientInfo = {
                id: clientID,
            };

            clients.push({
                id: doc.id
            });

            if (clientID == 'Duke University') {
                clientRef = db.collection('sectors').doc('athletics')
                                .collection('clients').doc(clientID)
                                .collection('teams').doc('Duke Football')
            } else {
                clientRef = db.collection('sectors').doc('athletics')
                                .collection('clients').doc(clientID)
                                .collection('teams').doc('Football')
            }

            
            // get information from orders collection (DONT loop through orders)
            await clientRef.collection('orders').get()
            .then(ordersSnap => {
                var orderCount = ordersSnap.size;
                clientInfo.orderCount = ordersSnap.size;
            })

            // get information from athletes collection (DONT loop through athletes)
            await clientRef.collection('athletes').get()
            .then(athletesSnap => {
                var athleteCount = athletesSnap.size;
                clientInfo.athleteCount = athletesSnap.size;
            })

            // add new clientInfo to state 
            const list = [...this.state.allClientInfo, clientInfo]

            this.setState({
                allClientInfo: list
            })
        });

        //this.setState({clients});
    })
    console.log(unsubscribe)
    return unsubscribe;
}


// load detail on every order for detail of a certain client
export var getClientDetail = function getClientDetail(clientID, startDate, endDate) {
    // use clientID to place listener on 

    console.log('getting client detail from id: '+clientID)

    // get total financials
    // for each doc in orders
    // get financial info and add to total

    console.log(clientID)

    // loop through each document in orders and aggregate data

    if (clientID == 'Duke University'){
        db.collection('sectors').doc('athletics')
        .collection('clients').doc(clientID)
        .collection('teams').doc('Duke Football')
        .collection('orders').where('timestamp', '>', startDate).where('timestamp', '<', endDate)
        .get().then(snapshot => {

        console.log('in loop')
        console.log(snapshot)
 
        // set base sum variables before looping through each order
        this.sumMaterialCost = 0;
        this.sumShippingCost = 0;
        this.sumTotalCost = 0;
        this.sumProductRevenue = 0;
        this.sumShippingRevenue = 0;
        this.sumTotalRevenue = 0;
        this.sumNetProfit = 0;

        this.sumModelingTime = 0;
        this.sumResinAmounts = {
            Durable: 0,
            Draft: 0,
            Elastic: 0,
            Gray: 0,
            Tough: 0,
            Tough1500: 0
        }

        snapshot.forEach((doc) => {

            console.log(doc.data())

            var mMaterialCost = doc.data().materialCost
            var mShippingCost = doc.data().shippingCost
            var mTotalCost = doc.data().totalCost
            var mProductRevenue = doc.data().productRevenue
            var mShippingRevenue = doc.data().shippingRevenue
            var mTotalRevenue = doc.data().totalRevenue
            var mNetProfit = doc.data().netProfit

            if(mMaterialCost != undefined) this.sumMaterialCost = Number(this.sumMaterialCost) + Number(mMaterialCost);
            if(mShippingCost != undefined) this.sumShippingCost = Number(this.sumShippingCost) + Number(mShippingCost);
            if(mTotalCost != undefined) this.sumTotalCost = Number(this.sumTotalCost) + Number(mTotalCost);
            if(mProductRevenue != undefined) this.sumProductRevenue = Number(this.sumProductRevenue) + Number(mProductRevenue);
            if(mShippingRevenue != undefined) this.sumShippingRevenue = Number(this.sumShippingRevenue) + Number(mShippingRevenue);
            if(mTotalRevenue != undefined) this.sumTotalRevenue = Number(this.sumTotalRevenue) + Number(mTotalRevenue);
            if(mNetProfit != undefined) this.sumNetProfit = Number(this.sumNetProfit) + Number(mNetProfit);

            // need to loop through versions array to get modeling times and resin amounts
            var mVersions = doc.data().versions

            if (mVersions != undefined) {
                for (var i=0; i<mVersions.length; i++) {
                    this.sumModelingTime = Number(this.sumModelingTime) + Number(mVersions[i].modelingTime)  
                    // loop through prints to check resin types
                    for (var k=0; k<mVersions[i].prints.length; k++) {
                        var mResinType = mVersions[i].prints[k].resinType;
                        var mResinAmount = mVersions[i].resinAmount;
                        if (mResinType != undefined) {
                            var newSumResinAmount = Number(this.sumResinAmounts[mResinType]) + Number(mResinAmount)
                            this.sumResinAmounts[mResinType] = newSumResinAmount.toFixed(1)
                        }
                    }               
                }
            }

        })

        this.setState({
            sumMaterialCost: Number(this.sumMaterialCost).toFixed(2),
            sumShippingCost: Number(this.sumShippingCost).toFixed(2),
            sumTotalCost: Number(this.sumTotalCost).toFixed(2),
            sumProductRevenue: Number(this.sumProductRevenue).toFixed(2),
            sumShippingRevenue: Number(this.sumShippingRevenue).toFixed(2),
            sumTotalRevenue: Number(this.sumTotalRevenue).toFixed(2),
            sumNetProfit: Number(this.sumNetProfit).toFixed(2),
            sumModelingTime: Number(this.sumModelingTime).toFixed(0),
            sumResinAmounts: this.sumResinAmounts
        })
        
    })
    //return unsubscribe;

    } else {

        console.log('start date', startDate)
        console.log('end date', endDate)
            db.collection('sectors').doc('athletics')
            .collection('clients').doc(clientID)
            .collection('teams').doc('Football')
            .collection('orders').where('timestamp', '>', startDate).where('timestamp', '<', endDate)
            .get().then(snapshot => {

            console.log('in loop')
            console.log(snapshot)

            // set base sum variables before looping through each order
            this.sumMaterialCost = 0;
            this.sumShippingCost = 0;
            this.sumTotalCost = 0;
            this.sumProductRevenue = 0;
            this.sumShippingRevenue = 0;
            this.sumTotalRevenue = 0;
            this.sumNetProfit = 0;

            this.sumModelingTime = 0;
            this.sumResinAmounts = {
                Durable: 0,
                Draft: 0,
                Elastic: 0,
                Gray: 0,
                Tough: 0,
                Tough1500: 0
            }

            snapshot.forEach((doc) => {

                console.log(doc.data())

                var mMaterialCost = doc.data().materialCost
                var mShippingCost = doc.data().shippingCost
                var mTotalCost = doc.data().totalCost
                var mProductRevenue = doc.data().productRevenue
                var mShippingRevenue = doc.data().shippingRevenue
                var mTotalRevenue = doc.data().totalRevenue
                var mNetProfit = doc.data().netProfit

                if(mMaterialCost != undefined) this.sumMaterialCost = Number(this.sumMaterialCost) + Number(mMaterialCost);
                if(mShippingCost != undefined) this.sumShippingCost = Number(this.sumShippingCost) + Number(mShippingCost);
                if(mTotalCost != undefined) this.sumTotalCost = Number(this.sumTotalCost) + Number(mTotalCost);
                if(mProductRevenue != undefined) this.sumProductRevenue = Number(this.sumProductRevenue) + Number(mProductRevenue);
                if(mShippingRevenue != undefined) this.sumShippingRevenue = Number(this.sumShippingRevenue) + Number(mShippingRevenue);
                if(mTotalRevenue != undefined) this.sumTotalRevenue = Number(this.sumTotalRevenue) + Number(mTotalRevenue);
                if(mNetProfit != undefined) this.sumNetProfit = Number(this.sumNetProfit) + Number(mNetProfit);

                // need to loop through versions array to get modeling times and resin amounts
                var mVersions = doc.data().versions

                if (mVersions != undefined) {
                    for (var i=0; i<mVersions.length; i++) {
                        this.sumModelingTime = Number(this.sumModelingTime) + Number(mVersions[i].modelingTime)  
                        // loop through prints to check resin types
                        for (var k=0; k<mVersions[i].prints.length; k++) {
                            var mResinType = mVersions[i].prints[k].resinType;
                            var mResinAmount = mVersions[i].resinAmount;
                            if (mResinType != undefined) {
                                var newSumResinAmount = Number(this.sumResinAmounts[mResinType]) + Number(mResinAmount)
                                this.sumResinAmounts[mResinType] = newSumResinAmount.toFixed(1)
                            }
                        }               
                    }
                }

            })

            this.setState({
                sumMaterialCost: Number(this.sumMaterialCost).toFixed(2),
                sumShippingCost: Number(this.sumShippingCost).toFixed(2),
                sumTotalCost: Number(this.sumTotalCost).toFixed(2),
                sumProductRevenue: Number(this.sumProductRevenue).toFixed(2),
                sumShippingRevenue: Number(this.sumShippingRevenue).toFixed(2),
                sumTotalRevenue: Number(this.sumTotalRevenue).toFixed(2),
                sumNetProfit: Number(this.sumNetProfit).toFixed(2),
                sumModelingTime: Number(this.sumModelingTime).toFixed(0),
                sumResinAmounts: this.sumResinAmounts
            })
            
        })
       // return unsubscribe;

    }
}

// Get all client numbers for all clients

export var getAllClientDetail = async function getAllClientDetail() {

    this.totalSumMaterialCost = this.state.totalSumMaterialCost;
    this.totalSumShippingCost = this.state.totalSumShippingCost;
    this.totalSumTotalCost = this.state.totalSumTotalCost;
    this.totalSumProductRevenue = this.state.totalSumProductRevenue;
    this.totalSumShippingRevenue = this.state.totalSumShippingRevenue;
    this.totalSumTotalRevenue = this.state.totalSumTotalRevenue;
    this.totalSumNetProfit = this.state.totalSumNetProfit;

    console.log(this.state)
    
    await db.collection('sectors').doc('athletics')
    .collection('clients').get().then(async snapshot => {

        await snapshot.forEach(async client => {
            //console.log(client.id)

            if (client.id == 'Duke University') {
                await db.collection('sectors').doc('athletics')
                .collection('clients').doc(client.id)
                .collection('teams').doc('Duke Football')
                .collection('orders').get().then(async snapshot=>{
    
                    await snapshot.forEach(async order => {
                        //console.log(order.id)
    
                        var mMaterialCost = order.data().materialCost
                        var mShippingCost = order.data().shippingCost
                        var mTotalCost = order.data().totalCost
                        var mProductRevenue = order.data().productRevenue
                        var mShippingRevenue = order.data().shippingRevenue
                        var mTotalRevenue = order.data().totalRevenue
                        var mNetProfit = order.data().netProfit
    
                        // console.log(mMaterialCost)
                        // console.log(mShippingCost)
                        // console.log(mTotalCost)
                        // console.log(mProductRevenue)
                        // console.log(mShippingRevenue)
                        // console.log(mTotalRevenue)
                        // console.log(mNetProfit)
    
    
                        if(mMaterialCost != undefined) this.totalSumMaterialCost = Number(this.totalSumMaterialCost) + Number(mMaterialCost);
                        if(mShippingCost != undefined) this.totalSumShippingCost = Number(this.totalSumShippingCost) + Number(mShippingCost);
                        if(mTotalCost != undefined) this.totalSumTotalCost = Number(this.totalSumTotalCost) + Number(mTotalCost);
                        if(mProductRevenue != undefined) this.totalSumProductRevenue = Number(this.totalSumProductRevenue) + Number(mProductRevenue);
                        if(mShippingRevenue != undefined) this.totalSumShippingRevenue = Number(this.totalSumShippingRevenue) + Number(mShippingRevenue);
                        if(mTotalRevenue != undefined) this.totalSumTotalRevenue = Number(this.totalSumTotalRevenue) + Number(mTotalRevenue);
                        if(mNetProfit != undefined) this.totalSumNetProfit = Number(this.totalSumNetProfit) + Number(mNetProfit);
    
                    })
    
                })
                console.log('matcost: '+this.totalSumMaterialCost)
                console.log('shippingcost: '+this.totalSumShippingCost)
                console.log('totalcost: '+this.totalSumTotalCost)
                console.log('prodrev: '+this.totalSumProductRevenue)
                console.log('shippingrev: '+this.totalSumShippingRevenue)
                console.log('totalrev: '+this.totalSumTotalRevenue)
                console.log('netprofit: '+this.totalSumNetProfit)
            } else {
                await db.collection('sectors').doc('athletics')
                .collection('clients').doc(client.id)
                .collection('teams').doc('Football')
                .collection('orders').get().then(async snapshot=>{
    
                    await snapshot.forEach(async order => {
                        //console.log(order.id)
    
                        var mMaterialCost = order.data().materialCost
                        var mShippingCost = order.data().shippingCost
                        var mTotalCost = order.data().totalCost
                        var mProductRevenue = order.data().productRevenue
                        var mShippingRevenue = order.data().shippingRevenue
                        var mTotalRevenue = order.data().totalRevenue
                        var mNetProfit = order.data().netProfit
    
                        // console.log(mMaterialCost)
                        // console.log(mShippingCost)
                        // console.log(mTotalCost)
                        // console.log(mProductRevenue)
                        // console.log(mShippingRevenue)
                        // console.log(mTotalRevenue)
                        // console.log(mNetProfit)
    
    
                        if(mMaterialCost != undefined) this.totalSumMaterialCost = Number(this.totalSumMaterialCost) + Number(mMaterialCost);
                        if(mShippingCost != undefined) this.totalSumShippingCost = Number(this.totalSumShippingCost) + Number(mShippingCost);
                        if(mTotalCost != undefined) this.totalSumTotalCost = Number(this.totalSumTotalCost) + Number(mTotalCost);
                        if(mProductRevenue != undefined) this.totalSumProductRevenue = Number(this.totalSumProductRevenue) + Number(mProductRevenue);
                        if(mShippingRevenue != undefined) this.totalSumShippingRevenue = Number(this.totalSumShippingRevenue) + Number(mShippingRevenue);
                        if(mTotalRevenue != undefined) this.totalSumTotalRevenue = Number(this.totalSumTotalRevenue) + Number(mTotalRevenue);
                        if(mNetProfit != undefined) this.totalSumNetProfit = Number(this.totalSumNetProfit) + Number(mNetProfit);
    
                    })
    
                })
                console.log('matcost: '+this.totalSumMaterialCost)
                console.log('shippingcost: '+this.totalSumShippingCost)
                console.log('totalcost: '+this.totalSumTotalCost)
                console.log('prodrev: '+this.totalSumProductRevenue)
                console.log('shippingrev: '+this.totalSumShippingRevenue)
                console.log('totalrev: '+this.totalSumTotalRevenue)
                console.log('netprofit: '+this.totalSumNetProfit)
            }

        })

    })

    // Number(this.totalSumMaterialCost).toFixed(2)
    // Number(this.totalSumShippingCost).toFixed(2)
    // Number(this.totalSumTotalCost).toFixed(2)
    // Number(this.totalSumProductRevenue).toFixed(2)
    // Number(this.totalSumShippingRevenue).toFixed(2)
    // console.log(Number(this.totalSumNetProfit).toFixed(2))
    //console.log(Number(this.totalSumTotalRevenue).toFixed(2))
    

    this.setState({
        totalSumMaterialCost: Number(this.totalSumMaterialCost).toFixed(2),
        totalSumShippingCost: Number(this.totalSumShippingCost).toFixed(2),
        totalSumTotalCost: Number(this.totalSumTotalCost).toFixed(2),
        totalSumProductRevenue: Number(this.totalSumProductRevenue).toFixed(2),
        totalSumShippingRevenue: Number(this.totalSumShippingRevenue).toFixed(2),
        totalSumTotalRevenue: Number(this.totalSumTotalRevenue).toFixed(2),
        totalSumNetProfit: Number(this.totalSumNetProfit).toFixed(2)
    })
}

// Place listener on single order for Detail page

export var getOrderDetail = function getOrderDetail(orderRef) {

    console.log('getting order detail from ref: '+orderRef);

    var sector = orderRef.split('/')[1];
    var client = orderRef.split('/')[3];
    var team = orderRef.split('/')[5];
    var orderID = orderRef.split('/')[7];

    let mOrderInfo;

    const unsubscribe =  db.collection('sectors').doc(sector)
        .collection('clients').doc(client)
        .collection('teams').doc(team)
        .collection('orders').doc(orderID).onSnapshot(doc => {

            console.log('found document')
            console.log(doc.data())

            mOrderInfo = {
                name: doc.data().name,
                number: doc.data().number,
                client: client,
                team: doc.data().team,
                position: doc.data().position,

                orderID: doc.data().orderID,
                orderStatus: doc.data().orderStatus,
                internalStatus: doc.data().internalStatus,

                bodyPart: doc.data().bodyPart,
                padType: doc.data().padType,
                injuryDesc: doc.data().injuryDesc,
                designModificationComments: doc.data().designModificationComments,
                pair: doc.data().pair,
                reviewModel: doc.data().reviewModel,
                timelineComments: doc.data().timelineComments,
                timelinePriority: doc.data().timelinePriority,

                fileLink: doc.data().fileLink,
                ref: doc.ref.path,
                id: doc.id,

                versions: doc.data().versions,

                invoiceNumber: doc.data().invoiceNumber,
                productRevenue: doc.data().productRevenue,
                shippingRevenue: doc.data().shippingRevenue,
                totalRevenue: doc.data().totalRevenue,
                materialCost: doc.data().materialCost,
                shippingCost: doc.data().shippingCost,
                totalCost: doc.data().totalCost,
                netProfit: doc.data().netProfit,

            }

            console.log(mOrderInfo)

            // check for orderID and set to '' if null
            if (mOrderInfo.orderID == null ) {
                mOrderInfo.orderID = '';
            }

            // check for versions and set to [] if null
            if (mOrderInfo.versions == null) {
                mOrderInfo.versions = [];
            }

            // check for internalStatus and set to 0 if the field is null
            if (mOrderInfo.internalStatus == null) {
                mOrderInfo.internalStatus = '0';
            }

            // check for invoiceNumber and set to 0 if the field is null
            if (mOrderInfo.invoiceNumber == null) {
                mOrderInfo.invoiceNumber = 0;
            }
            // check for productRevenue and set to '' if field is null
            if (mOrderInfo.productRevenue == null) {
                mOrderInfo.productRevenue = 0;
            }

            // check for shippingRevenue and set to '' if field is null
            if (mOrderInfo.shippingRevenue == null) {
                mOrderInfo.shippingRevenue = 0;
            }

            // check for totalRevenue and set to '' if field is null
            if (mOrderInfo.totalRevenue == null) {
                mOrderInfo.totalRevenue = 0;
            }

            // check for materialCost and set to '' if field is null
            if (mOrderInfo.materialCost == null) {
                mOrderInfo.materialCost = 0;
            }

            // check for shippingCost and set to '' if field is null
            if (mOrderInfo.shippingCost == null) {
                mOrderInfo.shippingCost = 0;
            }

            // check for totalCost and set to '' if field is null
            if (mOrderInfo.totalCost == null) {
                mOrderInfo.totalCost = 0;
            }

            // check for netProfit and set to '' if field is null
            if (mOrderInfo.netProfit == null) {
                mOrderInfo.netProfit = 0;
            }



            console.log(mOrderInfo)
            this.setState({orderInfo: mOrderInfo, isLoading: false});
        });
    
    console.log('set order info as:' + this.state.orderInfo)

    return unsubscribe;
}

// Save data for order from Detail page

export var saveOrderDetail = async function saveOrderDetail() {

    var newName = this.state.name;
    var newNumber = this.state.number;
    var newPosition = this.state.position;
    var newOrderStatus = this.state.orderStatus;
    var newInternalStatus = this.state.internalStatus;
    var newPadType = this.state.padType;
    var newOrderID = this.state.orderID;

    var newInvoiceNumber = this.state.invoiceNumber;
    var newProductRevenue = this.state.productRevenue;
    var newShippingRevenue = this.state.shippingRevenue;
    var newTotalRevenue = this.state.totalRevenue;
    var newMaterialCost = this.state.materialCost;
    var newShippingCost = this.state.shippingCost;
    var newTotalCost = this.state.totalCost;
    var newNetProfit = this.state.netProfit;

    var newVersions = this.state.versions;


    console.log(this.state)

    var sector = this.state.ref.split('/')[1];
    var client = this.state.ref.split('/')[3];
    var team = this.state.ref.split('/')[5];
    var orderID = this.state.ref.split('/')[7];
    
    return await db.collection('sectors').doc(sector)
        .collection('clients').doc(client)
        .collection('teams').doc(team)
        .collection('orders').doc(orderID).update({
            name: newName,
            number: newNumber,
            position: newPosition,
            padType: newPadType,
            orderStatus: newOrderStatus,
            internalStatus: newInternalStatus,
            orderID: newOrderID,

            invoiceNumber: newInvoiceNumber,
            productRevenue: newProductRevenue,
            shippingRevenue: newShippingRevenue,
            totalRevenue: newTotalRevenue,
            materialCost: newMaterialCost,
            shippingCost: newShippingCost,
            totalCost: newTotalCost,
            netProfit: newNetProfit,   
            
            versions: newVersions
    })
    .then(() => {
        console.log('Document successfully updated!');
        var curShow = this.state.showToastSuccess;
        this.setState({showToastSuccess: !curShow})

    })
    .catch(function(error) {
        console.error('Error updating document: ', error)
        var curShow = this.state.showToastFailure;
        this.setState({showToastFailure: !curShow})
    })



    // return statement with update for each field

    // completion handler

    // error
}
import authReducer from './authReducer'
import clientReducer from './clientReducer'
import orderReducer from './orderReducer'
import toastReducer from './toastReducer'
import { combineReducers } from 'redux'
// import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore'

const rootReducer = combineReducers({
    // firebase: firebaseReducer,
    firestore: firestoreReducer,
    auth: authReducer,
    client: clientReducer,
    order: orderReducer,
    toast: toastReducer
});

export default rootReducer;
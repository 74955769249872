export const createClient = (client) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        // make async call to database

        console.log(getFirestore)

        const firestore = getFirestore();
        //firestore.collection('sectors').

        dispatch({ type: 'CREATE_CLIENT', client});
    }
};

// gets all clients and their order/athlete counts
// TODO: expand this to work for all sectors
export const getClients = () => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {

        const firestore = getFirestore();

        var clients = [];
        
        // for each athletics client
        firestore.collection('sectors').doc('athletics').collection('clients').get().then(snapshot => {
            snapshot.forEach(client  => {
                console.log(client)
                clients = [...clients, client]
                console.log(clients)

            });
        }).then(() => {
            dispatch({ type: 'GET_CLIENTS', clients})
        }).catch((err) => {
            console.log(err)
        })
    }
};
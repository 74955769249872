import firebase from 'firebase';

// Utils file for helper functions to be used broadly

// get sector, client, and team info from an order doc

export function getOrderLocation(refPath) {

    let pathSplit = refPath.split('/');

    let sector;
    let client;
    let team;

    for (var i=0; i < pathSplit.length; i++ ) {
        switch(pathSplit[i]) {
            case 'sectors':
                sector = pathSplit[i+1];
                break;
            case 'clients': 
                client = pathSplit[i+1];
                break;
            case 'teams':
                team = pathSplit[i+1];
                break;
            default:
                break;
        }
    }

    return {sector, client, team}
}

// get timestamp & id for button group changes

export function getUserTimestamp() {
    var date = Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(Date.now())
    var curUser = firebase.auth().currentUser.email.split('@')[0];
    return [date, curUser]
}

// decode internal status from numeral to string

export function decodeInternalStatus(internalStatusCode) {
    var internalStatusName = '';

    switch(internalStatusCode) {
        case '0':
            internalStatusName = 'Scan Received';
            break;
        case '1':
            internalStatusName = 'Scan Approved';
            break;
        case '2':
            internalStatusName = 'Model Completed';
            break;
        case '3':
            internalStatusName = 'Model Approved Sent';
            break;
        case '4':
            internalStatusName = 'Invoice Sent';
            break;
        case '5':
            internalStatusName = 'Invoice Paid';
            break;
        default:
            internalStatusName = '';
    }

    return internalStatusName;
}

// decode order status from numeral to string

export function decodeOrderStatus(orderStatusCode) {

    // if #, set return orderStatusName and set it to String
    // String, return orderStatusName
    // (7/16/21) – added logic to handle old numerical statuses as well as new strings - TJS

    var orderStatusName = '';

    switch(orderStatusCode) {
        case '0':
        case 'Local Order':
            orderStatusName = 'Local Order';
            break;
        case '1':
        case 'Submitted':
            orderStatusName = 'Submitted';
            break;
        case 'Scan Pending Approval':
            orderStatusName = 'Scan Pending Approval';
            break;
        case 'Scan Rejected':
            orderStatusName = 'Scan Rejected';
            break;
        case 'Scan Approved':
            orderStatusName = 'Scan Approved';
            break;
        case '2':
        case 'Designing':
            orderStatusName = 'Designing';
            break;
        case '3':
        case 'Design Pending Approval':
            orderStatusName = 'Design Pending Approval';
            break;
        case 'Design Rejected':
            orderStatusName = 'Design Rejected';
            break;
        case 'Design Approved':
            orderStatusName = 'Design Approved';
            break;
        case '4':
        case '3D Printing':
            orderStatusName = '3D Printing';
            break;
        case 'Print Successful':
            orderStatusName = 'Print Successful';
            break;
        case 'Shipped':
            orderStatusName = 'Shipped'
            break;
        case '5':
        case 'Received':
            orderStatusName = 'Received';
            break;
        default:
            orderStatusName = '';
    }

    return orderStatusName;
}

// get resin factor based on type of resin

export function getResinFactor(resinType) {
    var resinFactor = 0;

    switch(resinType) {
        case 'Durable':
            resinFactor = 0.175 + 0.0472 + 0.0283;
            break;
        case 'Elastic':
            resinFactor = 0.199 + 0.0396 + 0.0283;
            break;
        case 'Draft':
            resinFactor = 0.15 + 0.0472 + 0.0072;
            break;
        case 'Tough 1500':
            resinFactor = 0.175 + 0.0396 + 0.0283;
            break;
        case 'Tough':
            resinFactor = 0.175 + 0.0472 + 0.0283;
            break;
        case 'Gray':
            resinFactor = 0.15 + 0.0472 + 0.0283;
            break;
        default:
            resinFactor = 0;
    }

    return resinFactor;
}

// generate order ID for a new order

export function generateOrderID(orderData) {

    // console.log(this.state)

    var clientID;
    var teamID;
    var nameID;
    var numID;
    var deviceID;

    switch(orderData.client){
        case 'Duke University':
            clientID = 'DK';
            break;
        case 'Wake Forest':
            clientID = 'WF';
            break;
        case 'NC State University':
            clientID = 'NCS';
            break;
        case 'Carolina Panthers':
            clientID = 'PA';
            break;
        case 'New York Guardians':
            clientID = 'GD';
            break;
        case 'San Francisco 49ers':
            clientID = 'SF';
            break;
        case 'Texas A&M':
            clientID = 'TAM';
            break;
        case 'Lousiana Tech University':
            clientID = 'LAT';
            break;
        case 'Florida State':
            clientID = 'FSU';
            break;
        case 'Eastern Carolina University':
            clientID = 'ECU';
            break;
        case 'Kansas City Chiefs':
            clientID = 'KC';
            break;
        case 'University of Southern California':
            clientID = 'USC';
            break;
        default:
            clientID='[client]';
    }

    switch(orderData.team){

        case 'Duke Football':
          teamID = 'FB';
          break;
        case 'Football':
          teamID = 'FB';
          break;
        case 'Baseball':
          teamID = 'BS';
          break;
        case "Men's Basketball":
          teamID = 'MB';
          break;
        case "Women's Lacrosse":
          teamID = 'WL';
          break;
        case "Men's Lacrosse":
          teamID = 'ML';
          break;
        case 'Medical':
          teamID = 'MD';
          break;
        case "Women's Soccer":
          teamID = 'WS';
          break;
        default:
          teamID = '[team]';
    }

    switch(orderData.padType){

        // arm
        case 'Bicep Pad':
            deviceID = 'BP';
            break;
        case 'Forearm Pad':
            deviceID = 'FP';
            break;
            
        // foot
        case 'Ankle Brace':
            deviceID = 'AB';
            break;
        case 'Foot Clam Shell':
            deviceID = 'FCS';
            break;
            
        // hand
        case 'Finger Splint':
            deviceID = 'FS';
            break;
        case 'Gutter Splint':
            deviceID = 'GS';
            break;
        case 'Thumb Guard':
            deviceID = 'TG';
            break;
        case 'Wrist Club':
            deviceID = 'WC';
            break;
        case 'Wrist Splint':
            deviceID = 'WS';
            break;
        case 'Small Thumb Splint':
            deviceID = 'STS';
            break;
        case 'Ringed Thumb Splint':
            deviceID = 'RTS';
            break;
            
        // leg
        case 'Hip Pointer Pad':
            deviceID = 'HPP';
            break;
        case 'Shin Guard':
            deviceID = 'SG';
            break;
        case 'Thigh Pad':
            deviceID = 'TP';
            break;
            
        // torso
        case 'AC Joint Pad':
            deviceID = 'AC';
            break;
        case 'Collarbone Pad':
            deviceID = 'CB';
            break;
        case 'Rib Pad':
            deviceID = 'RP';
            break;
        case 'SC Joint Pad':
            deviceID = 'SC';
            break;
        case 'Sternum Pad':
            deviceID = 'ST';
            break;

        // Custom solution
        case 'Custom Solution':
            deviceID = 'CS';
            break;
        default: 
            deviceID = '[device]';
    }

    var nameSplit = orderData.name.split(' ');
    console.log(nameSplit)
    if (nameSplit[1]) {
        nameID = nameSplit[0].split('')[0]+nameSplit[1].split('')[0]
    } else {
        nameID = nameSplit[0].split('')[0]
    }

    numID = orderData.number

    var orderID = clientID+teamID+nameID+numID+deviceID;
    console.log(orderID)

    return orderID;
}
import React from 'react';
import {withRouter} from 'react-router-dom';
import SignInView from './SignInView';
import firebase from '../Firebase';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Card, Button, Form} from 'react-bootstrap';

class SignInContainer extends React.Component {
    
    constructor(props) {
        super(props);
        this.inputEmail = React.createRef();
        this.inputPassword = React.createRef();
    }

    render() {
        return (
            <div style={mainPage}>
            <Card style={signInCard}>
                <Card.Header>Sign In</Card.Header>
                <Card.Body>
                    <Form onSubmit={this.handleSignIn.bind(this)}>
                        <Form.Group controlID="email">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" ref={this.inputEmail}/>
                        </Form.Group>
                        <Form.Group controlID="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" ref={this.inputPassword}/>
                        </Form.Group>
                        <Button variant="primary" type="submit" onClick={this.checkUser}>Submit</Button>
                    </Form>
                </Card.Body>
            </Card>
        </div>
        );
    }


    async handleSignIn(event) {

        console.log('event triggered');
        event.preventDefault();

        try{

            var mEmail = this.inputEmail.current.value;

            console.log(mEmail);

            const user = await firebase
                .auth()
                .signInWithEmailAndPassword(this.inputEmail.current.value, this.inputPassword.current.value).catch(function(error){
                    alert(error);
                });
            console.log('passed sign in');
            } catch (error) {
                alert(error);
            }

        this.props.history.push('/home');
    };
    
    checkUser(){
        console.log('func triggered');
        //var currentUser = firebase.auth.currentUser;
    
        firebase.auth().onAuthStateChanged(function(user){
            if (user){
                console.log(user.email)
            } else{
                console.log('no user')
            }
        })
    };
}

export default withRouter(SignInContainer);

var signInCard = {
    padding: '40px',
    width: '50%'
}

var mainPage ={
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%'
}
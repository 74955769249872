import React, {useState} from 'react';
import firebase from '../Firebase';
import {Spinner, Card, Row, Col, Button, InputGroup, DropdownButton, Dropdown, FormControl, ButtonGroup, Form, ListGroup, Toast, Alert, Accordion} from 'react-bootstrap';
import { func } from 'prop-types';
import DesignVersion from './DesignVersion'

//import {saveOrderDetail} from './Firebase';
import {decodeInternalStatus, decodeOrderStatus} from '../../utils/Utils';
import { version, decode } from 'punycode';

import { connect } from 'react-redux';
import { getFirestore } from 'redux-firestore';
import { handleOrderDetailChange, addNewDesignVersion, updateFinancialInfo, saveOrderDetail, setCurrentOrder, uploadRenderFiles } from '../../actions/orderActions';
import { addToast } from '../../actions/toastActions';
import { EventEmitter } from 'events';
import { white } from 'color-name';

const db = firebase.firestore();

class OrderDetail extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            showToastSuccess: false,
            showToastFailure: false,

            productRenderFile: null,
            scanRenderFile: null,
            textRenderFile: null
        }

        this.decodeInternalStatus = decodeInternalStatus.bind(this);
        this.decodeOrderStatus = decodeOrderStatus.bind(this);
        
        this.toggleToastSuccess = this.toggleToastSuccess.bind(this);
        this.toggleToastFailure = this.toggleToastFailure.bind(this);

        this.clientInput = React.createRef();
        this.teamInput = React.createRef();
        this.nameInput = React.createRef();
        this.numberInput = React.createRef();
        this.positionInput = React.createRef();

        this.padTypeInput = React.createRef();
        this.injuryDescInput = React.createRef();
        this.versionCountInput = React.createRef();


    }

    // show Toast for completed FB save
    toggleToastSuccess() { 
        var curShow = this.state.showToastSuccess;
        this.setState({showToastSuccess: !curShow})
    }

    // show Toast for failed FB save
    toggleToastFailure() { 
        var curShow = this.state.showToastFailure;
        this.setState({showToastFailure: !curShow})
    }

    componentDidMount() {
        if (this.props.curOrder == null) {
            console.log('here)')
            console.log(this.props.location.hash)
            let re = /%20/gi
            this.props.setCurrentOrder(this.props.location.hash.split('#')[1].replace(re, ' '))
        }
    }

    render() {

        console.log('rendering Order Detail with these props')
        console.log(this.props)
        const curOrder = this.props.curOrder;

        if (curOrder == null) {
            return <Spinner animation='border' variant='primary'/>
        }

         else { 

            var versionListItems = [];

            if (curOrder.versions != undefined) {
                versionListItems = curOrder.versions.map((version, index) => 
                    <DesignVersion 
                    dataFromParent = {version} 
                    versionIndex = {index} 
                    deleteVersionFromParent = {this.newDeleteVersion}/>
                )
            }
             
            return(
                <div>
                    <Card style={infoCard}>
                            <h2>General Information</h2>
                        <Col>
                            <Form className="mb-3">
                                <Form.Label>Client</Form.Label>
                                <Form.Control
                                    readOnly
                                    ref = {this.clientInput}
                                    value={curOrder.client}
                                    id = 'client'
                                    aria-label="Client"
                                    aria-describedby="client"
                                    onChange = {this.handleChange.bind(this)}
                                />
                                <Form.Label>Team</Form.Label>
                                <Form.Control
                                    readOnly 
                                    ref = {this.teamInput}
                                    value={curOrder.team}
                                    id = 'team'
                                    aria-label="Team"
                                    aria-describedby="team"
                                    onChange = {this.handleChange.bind(this)}
                                />
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    readOnly
                                    ref = {this.nameInput}
                                    value={curOrder.name}
                                    id = 'name'
                                    aria-label="Name"
                                    aria-describedby="name"
                                    onChange = {this.handleChange.bind(this)}
                                />
                                <Form.Label>Number</Form.Label>
                                <Form.Control
                                    readOnly    
                                    ref = {this.numberInput}
                                    value = {curOrder.number}
                                    id = 'number'
                                    aria-label="Number"
                                    aria-describedby="number"
                                    onChange = {this.handleChange.bind(this)}
                                />
                                <Form.Label>Position</Form.Label>
                                <Form.Control
                                    readOnly
                                    ref = {this.positionInput}
                                    value={curOrder.position}
                                    id = 'position'
                                    onChange = {this.handleChange.bind(this)}
                                />
                                <Form.Label>Order ID</Form.Label>
                                <Form.Control
                                    readOnly
                                    value={curOrder.orderID}
                                    id = 'orderID'
                                    onChange = {this.handleChange.bind(this)}
                                />
                                <Form.Label>Tracking Number/Link</Form.Label>
                                <Form.Control
                                    value={curOrder.shippingTrackingNumber}
                                    id = 'shippingTrackingNumber'
                                    onChange = {this.handleChange.bind(this)}
                                />

                            </Form>
                        </Col>

                        <Col>
                            <Row>
                                <Button variant="success" onClick={this.newSaveChanges}>Save Changes</Button>
                            </Row>
                            <Row>
                                <h3>Order Status 
                                    <Alert variant="light">Current local status is: {curOrder.orderStatus}</Alert></h3>
                                
                                <DropdownButton 
                                    variant="secondary"
                                    id="order-status-button" 
                                    title={this.decodeOrderStatus(curOrder.orderStatus)}
                                    ref={this.decodeOrderStatus(curOrder.orderStatus)}
                                    onSelect={eventKey => this.handleOrderStatusDropdownChange(eventKey)}>
                                    <Dropdown.Item eventKey="Submitted">Submitted</Dropdown.Item>
                                    <Dropdown.Item eventKey="Scan Pending Approval">Scan Pending Approval</Dropdown.Item>
                                    <Dropdown.Item eventKey="Scan Rejected">1a. Scan Rejected</Dropdown.Item>
                                    <Dropdown.Item eventKey="Scan Approved">1b. Scan Approved</Dropdown.Item>
                                    <Dropdown.Item eventKey="Designing">Designing</Dropdown.Item>
                                    <Dropdown.Item eventKey="Design Pending Approval">2. Design Pending Approval</Dropdown.Item>
                                    <Dropdown.Item eventKey="Design Rejected">Design Rejected</Dropdown.Item>
                                    <Dropdown.Item eventKey="Design Approved">Design Approved</Dropdown.Item>
                                    <Dropdown.Item eventKey="3D Printing">3. 3D Printing</Dropdown.Item>
                                    <Dropdown.Item eventKey="Print Successful">4. Print Successful</Dropdown.Item>
                                    <Dropdown.Item eventKey="Shipped">5. Shipped</Dropdown.Item>
                                    <Dropdown.Item eventKey="Received">6. Received</Dropdown.Item>

                                </DropdownButton>
                            </Row>
                            <Row>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>Upload File Buttons</Card.Title>
                                        <Card.Subtitle>Add each file design file for scan, product, and text</Card.Subtitle>
                                        <Row><Card>
                                            <label><b>Product Render</b></label>
                                            <input type="file" name="productRender" onChange={this.onFileChange}/>
                                            <label>Current file is: </label>
                                            <a href={curOrder.designProductLink}>{curOrder.designProductLink}</a>
                                        </Card></Row>
                                        <Row><Card>
                                            <label><b>Scan Render</b></label>
                                            <input type="file" name="scanRender" onChange={this.onFileChange}/>
                                            <label>Current file is:</label>
                                            <a href={curOrder.designScanLink}>{curOrder.designScanLink}</a>
                                        </Card></Row>
                                        <Row><Card>
                                            <label><b>Text Render</b></label>
                                            <input type="file" name="textRender" onChange={this.onFileChange}/>
                                            <label>Current file is:</label>
                                            <a href={curOrder.designTextLink}>{curOrder.designTextLink}</a>
                                        </Card></Row>
                                        <Button onClick={this.uploadRenderFiles}>Upload Files</Button>

                                    </Card.Body>

                                </Card>
                            </Row>
                            {/* <Row>
                            <h3>Internal Status <Badge pill variant="secondary">{curOrder.internalStatus}</Badge></h3>
                                
                                <DropdownButton 
                                    variant="secondary"
                                    id="order-status-button" 
                                    title={this.decodeInternalStatus(curOrder.internalStatus)}
                                    ref={this.decodeInternalStatus(curOrder.internalStatus)}
                                    onSelect={eventKey => this.handleInternalStatusDropdownChange(eventKey)}>

                                    <Dropdown.Item eventKey="0">Scan Received</Dropdown.Item>
                                    <Dropdown.Item eventKey="1">Scan Approved</Dropdown.Item>
                                    <Dropdown.Item eventKey="2">Model Completed</Dropdown.Item>
                                    <Dropdown.Item eventKey="3">Model Approved</Dropdown.Item>
                                    <Dropdown.Item eventKey="4">Invoice Sent</Dropdown.Item>
                                    <Dropdown.Item eventKey="5">Invoice Paid</Dropdown.Item>

                                </DropdownButton>
                            </Row> */}
                        </Col>
                    </Card>
                    <Card style={infoCard}>
                        <h2>Device Information</h2>
                        <Col>
                            <Form className="mb-3">
                                    <Form.Label>Body Part</Form.Label>
                                    <Form.Control
                                        readOnly
                                        ref = {this.bodyPartInput}
                                        value={curOrder.bodyPart}
                                        id = 'bodyPart'
                                        onChange = {this.handleChange.bind(this)}
                                    />
                                    <Form.Label>Device Type</Form.Label>
                                    <Form.Control
                                        readOnly
                                        ref = {this.padTypeInput}
                                        value={curOrder.padType}
                                        id = 'padType'
                                        onChange = {this.handleChange.bind(this)}
                                        />  
                                    
                                    <Form.Label>Injury Description</Form.Label>
                                    <Form.Control
                                        readOnly
                                        ref = {this.injuryDescInput}
                                        value={curOrder.injuryDesc}
                                        placeholder='No injury description provided'
                                        id = 'injuryDesc'
                                        onChange = {this.handleChange.bind(this)}
                                        />

                                    <Form.Label>Design Modification Comments</Form.Label>
                                    <Form.Control
                                        readOnly
                                        ref = {this.designModificationComments}
                                        value={curOrder.designModificationComments}
                                        placeholder='No design modification comments provided'
                                        id = 'designModificationComments'
                                        onChange = {this.handleChange.bind(this)}
                                    />
                                    <Form.Label>Pair</Form.Label>
                                    <Form.Control
                                        readOnly
                                        ref = {this.pairInput}
                                        value={curOrder.pair}
                                        id = 'pair'
                                        onChange = {this.handleChange.bind(this)}
                                    />
                                    <Form.Label>Review Model</Form.Label>
                                    <Form.Control
                                        readOnly
                                        ref = {this.reviewModelInput}
                                        value={curOrder.reviewModel}
                                        id = 'reviewModel'
                                        onChange = {this.handleChange.bind(this)}
                                    />
                                    <Form.Label>Timeline Comments</Form.Label>
                                    <Form.Control
                                        readOnly
                                        ref = {this.timelineCommentsInput}
                                        value={curOrder.timelineComments}
                                        placeholder='No timeline comments provided'
                                        id = 'timelineComments'
                                        onChange = {this.handleChange.bind(this)}
                                    />
                                    <Form.Label>Timeline Priority</Form.Label>
                                    <Form.Control
                                        readOnly
                                        ref = {this.timelinePriorityInput}
                                        value={curOrder.timelinePriority}
                                        id = 'timelinePriority'
                                        onChange = {this.handleChange.bind(this)}
                                    />
                            </Form>
                        </Col>
                    </Card>
                    <Card style={infoCard}>
                        <Col>
                            <Row>
                                <Col><h2>Design History</h2></Col>
                                <Col><Button variant="outline-primary" onClick={this.props.addNewDesignVersion}>Start New Version</Button></Col>
                            </Row>
                            <Row>
                                <Col><ListGroup>{versionListItems}</ListGroup></Col>
                            </Row>
                        </Col>
                    </Card>
                    <Card style={infoCard}>
                        <h2>Invoicing</h2>
                        <Col>
                        <Button onClick={this.props.updateFinancialInfo}>Update costs</Button>
                            <Form className='mb-3'>
                                <Form.Label>Invoice Number</Form.Label>
                                <Form.Control
                                    ref = {this.invoiceNumberInput}
                                    value={curOrder.invoiceNumber}
                                    id = 'invoiceNumber'
                                    onChange = {this.handleChange.bind(this)}
                                />
                                <Row>
                                    <Col>
                                        <Form.Label>Product Revenue</Form.Label>
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>$</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                ref = {this.productRevenueInput}
                                                value={curOrder.productRevenue}
                                                id = 'productRevenue'
                                                onChange = {this.handleChange.bind(this)}
                                            />
                                        </InputGroup>
                                        <Form.Label>Shipping Revenue</Form.Label>
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>$</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                ref = {this.shippingRevenueInput}
                                                value={curOrder.shippingRevenue}
                                                id = 'shippingRevenue'
                                                onChange = {this.handleChange.bind(this)}
                                            />
                                        </InputGroup>
                                        <Form.Label>Total Revenue</Form.Label>
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>$</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                readOnly
                                                ref = {this.totalRevenueInput}
                                                value={curOrder.totalRevenue}
                                                id = 'totalRevenue'
                                                onChange = {this.handleChange.bind(this)}
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <Form.Label>Material Cost</Form.Label>
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>$</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                readOnly
                                                ref = {this.materialCostInput}
                                                value={curOrder.materialCost}
                                                id = 'materialCost'
                                                onChange = {this.handleChange.bind(this)}
                                            />
                                        </InputGroup>
                                        <Form.Label>Shipping Cost</Form.Label>
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>$</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                ref = {this.shippingCostInput}
                                                value={curOrder.shippingCost}
                                                id = 'shippingCost'
                                                onChange = {this.handleChange.bind(this)}
                                            />
                                        </InputGroup>
                                        <Form.Label>Total Cost</Form.Label>
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>$</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                readOnly
                                                ref = {this.totalCostInput}
                                                value={curOrder.totalCost}
                                                id = 'totalCost'
                                                onChange = {this.handleChange.bind(this)}
                                            />
                                        </InputGroup> 
                                    </Col>
                                </Row>
                                <Form.Label>Net Profit</Form.Label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>$</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                    readOnly
                                    ref = {this.netProfitInput}
                                    value={parseFloat(curOrder.totalRevenue - curOrder.totalCost).toFixed(2)}
                                    id = 'netProfit'
                                    onChange = {this.handleChange.bind(this)}
                                    />
                                </InputGroup>
                            </Form>
                        </Col>
                    </Card>
                    
                    <Toast 
                        style={{position: 'absolute', top: 50, right: 50, width: '500px', zIndex: 10, backgroundColor: '#0292cd'}} 
                        onClose={this.toggleToastSuccess} 
                        show={this.state.showToastSuccess} 
                        delay={5000} 
                        autohide>
                        <Toast.Header>
                            <strong className="mr-auto">Save successful!</strong>
                            <small>just now</small>
                        </Toast.Header>
                        <Toast.Body>Your changes to this order detail have been saved successfully.</Toast.Body>
                    </Toast>

                    <Toast 
                        style={{position: 'absolute', top: 100, right: 50, width: '500px', zIndex: 10, backgroundColor: 'red'}} 
                        onClose={this.toggleToastFailure} 
                        show={this.state.showToastFailure} 
                        delay={5000} 
                        autohide>
                        <Toast.Header>
                            <strong className="mr-auto">Warning: Save unsuccessful.</strong>
                            <small>just now</small>
                        </Toast.Header>
                        <Toast.Body>An error occured and your changes were not saved. Please try again.</Toast.Body>
                    </Toast>


                    }
                </div> 
            )   
        };
    }


    handleOrderStatusDropdownChange(eventKey) {
        console.log('order status dropdown change triggered')
        
        switch (eventKey) {
            case 'Design Pending Approval':
                // check to ensure that all design links are present, and save if so
                // if not, send error toast
                if ((this.props.curOrder.designProductLink != "" && this.props.curOrder.designProductLink != null) &&
                    (this.props.curOrder.designScanLink != "" && this.props.curOrder.designScanLink != null) &&
                    (this.props.curOrder.designTextLink != "" && this.props.curOrder.designTextLink != null))  {
                        this.props.handleOrderDetailChange('orderStatus', eventKey)
                    } else {
                    const DPAErrorToast={
                        title: 'Status Not Allowed',
                        body: 'In order to save the order as Design Pending Approval, you must upload files for product, scan, and text renders.',
                    }
                    this.props.addToast(DPAErrorToast)
                }
                break;
            case 'Shipped':
                // check to ensure that tracking number is present, and save if so
                // if not, send error toast
                if ((this.props.curOrder.shippingTrackingNumber != "" && this.props.curOrder.shippingTrackingNumber != null)) {
                        this.props.handleOrderDetailChange('orderStatus', eventKey)
                } else {
                    const ShippedErrorToast={
                        title: 'Status Not Allowed',
                        body: 'Please provide a shipping tracking link to save Order Status as Shipped'
                    }
                    this.props.addToast(ShippedErrorToast)
                }
                break;
            default:
                // save
                this.props.handleOrderDetailChange('orderStatus', eventKey)
                break;
        }   
    }

    // handle standard input change
    handleChange(event) {
        
        event.persist()
        this.setState({[event.target.id]: event.target.value})

        // send new value to store
        this.props.handleOrderDetailChange(event.target.id, event.target.value);

        if (event.target.id == 'productRevenue' ||
            event.target.id == 'shippingRevenue' ||
            event.target.id == 'materialCost' ||
            event.target.id == 'shippingCost') {
                this.props.updateFinancialInfo();
        }
    }

    // Add new design version for specific order
    addNewVersion = () => {
        console.log('adding new version with store')
        this.props.addNewDesignVersion()
    }

    // Save changes wrapper (may be unnecessary)
    newSaveChanges = () => {
        console.log(this.props.curOrder)

        let curStatus = this.props.curOrder.orderStatus;

        // switch (curStatus) {
        //     case 'Design Pending Approval':
        //         // check to ensure that all design links are present, and save if so
        //         // if not, send error toast
        //         if (this.props.curOrder.designProductLink != "" &&
        //             this.props.curOrder.designScanLink != "" &&
        //             this.props.curOrder.designTextLink != "") {
        //                 this.props.saveOrderDetail(this.props.curOrder)
        //         } else {
        //             const DPAErrorToast={
        //                 title: 'Save Not Allowed',
        //                 body: 'In order to save the order as Design Pending Approval, you must upload files for product, scan, and text renders.',
        //             }
        //             this.props.addToast(DPAErrorToast)
        //         }
        //         break;
        //     case 'Shipped':
        //         // check to ensure that tracking number is present, and save if so
        //         // if not, send error toast
        //         if (this.props.curOrder.shippingTrackingNumber != "") {
        //                 this.props.saveOrderDetail(this.props.curOrder)
        //         } else {
        //             const ShippedErrorToast={
        //                 title: 'Save Not Allowed',
        //                 body: 'Please provide a shipping tracking link to save Order Status as Shipped'
        //             }
        //             this.props.addToast(ShippedErrorToast)
        //         }
        //         break;
        //     default:
        //         // save
                this.props.saveOrderDetail(this.props.curOrder)
                // break;
        // }
    }

    // Select files from computer
    onFileChange = (e) => {
        console.log('changing file in store', e.target)
        console.log('chosen file: ', e.target.files)
        this.setState({ [e.target.name+'File']: e.target.files[0]})
        console.log(this.state)

    }

    // Upload render files to Fb
    uploadRenderFiles = () => {
        console.log('uploading selected files')

        var files = [
            this.state.productRenderFile,
            this.state.scanRenderFile,
            this.state.textRenderFile
        ]

        console.log(this.props)
        console.log(this.state)

        var storagePath = 
            this.props.curOrder.orderSector+'/'+
            this.props.curOrder.orderClient+'/'+
            this.props.curOrder.orderTeam;
        
        var dbPath =
            'sectors/'+this.props.curOrder.orderSector+'/'+
            'clients/'+this.props.curOrder.orderClient+'/'+
            'teams/'+this.props.curOrder.orderTeam+'/orders'

        this.props.uploadRenderFiles(storagePath, dbPath, this.props.curOrder.padUniqueID, files)

    }

    // Debug add toast
    testAddToast = () => {

        const options={
            title: 'Test Title',
            body: 'Test Body',
        }

        this.props.addToast(options);
    }
}

// --------------------
// Redux

const mapStateToProps = (state) => {
    console.log(state)
    return {
        curOrderID: state.order.currentOrderID,
        curOrder: state.order.curOrder
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleOrderDetailChange: (id, value) => dispatch(handleOrderDetailChange(id, value)),
        addNewDesignVersion: () => dispatch(addNewDesignVersion()),
        updateFinancialInfo: () => dispatch(updateFinancialInfo()),
        saveOrderDetail: (curOrder) => dispatch(saveOrderDetail(curOrder)),
        addToast: (options) => dispatch(addToast(options)),
        setCurrentOrder: (uniqueID) => dispatch(setCurrentOrder(uniqueID)),
        uploadRenderFiles: (storagePath, dbPath, orderID, files) => dispatch(uploadRenderFiles(storagePath, dbPath, orderID, files))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail);

var infoCard = {
    display: 'flex',
    flexDirection: 'row'
}
import React from 'react';
import firebase from 'firebase';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container, Row, Col, Card, Button, ListGroup} from 'react-bootstrap'
import Client from './Client';

//import {getClientInfo, getClients, getAllClientDetail} from './Firebase';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

import { createClient, getClients } from '../../actions/clientActions';

const db = firebase.firestore();

class Clients extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            clients: [],
            allClientInfo: [],

            totalSumMaterialCost: 0,
            totalSumShippingCost: 0,
            totalSumTotalCost: 0,
            totalSumProductRevenue: 0,
            totalSumShippingRevenue: 0,
            totalSumTotalRevenue: 0,
            totalSumNetProfit: 0
    
        }

        //this.getClientInfo = getClientInfo.bind(this)
        //this.getClients = getClients.bind(this)
        //this.getAllClientDetail = getAllClientDetail.bind(this)
        this.stopListener = this.stopListener.bind(this)

        let clientsUnsubRef;
    }

    stopListener = (unsubRef) => {
        unsubRef();
    }

    componentWillReceiveProps(prevprops) {
        console.log(prevprops)
        console.log(this.props)
    }   
    
    componentDidMount() {

        //get clients
        //this.clientsUnsubRef = this.getClientInfo();
        //console.log(this.clientsUnsubRef)
        // this.getAllClientsInfo()
    }

    componentWillUnmount() {
        this.stopListener(this.clientsUnsubRef)
    }

    render() {

        console.log('rendering Clients with these props')  
        console.log(this.props)
        //console.log(this.state)  

        const clientInfo = this.state.allClientInfo;
        //const clientList = clientInfo.map((client) => <Client dataFromParent = {client}/>);
        let clientList = [];
        // if (this.props.clients != null) {
        //     clientList = this.props.clients.map((client) => {
        //         console.log(client)
        //         return(<Client dataFromParent = {client}/>)
        //     });
        // }
        console.log(JSON.stringify(this.props.clients))
        console.log(this.props.clients)
        clientList = this.props.clients.map((client) => {
            console.log(JSON.stringify(client))
            return(<Client dataFromParent = {client}/>)
        });
        return (
            <div>
                <Container>
                    <Row>
                        <Col>
                            <h1 className="text-center">Clients</h1>
                            <Button onClick={this.getAllClientDetail}>Get all client info</Button>
                            <Button onClick={this.createClientTest}>add client</Button>
                        </Col>
                    </Row>
                </Container>

                <div style={clientTable}>
                    <Card style={headerBar}>
                        <Row>
                            <Col>
                                <Button style={menuButton}>Client</Button>
                            </Col>
                            <Col>
                                <Button style={menuButton}>Order Count</Button>
                            </Col>
                            <Col>
                                <Button style={menuButton}>Athlete Count</Button>
                            </Col>
                            <Col>Client Detail</Col>
                        </Row>
                    </Card>

                    <ListGroup>{clientList}</ListGroup>
                </div>
            </div>
        )
    }

    createClientTest = (e) => {
        e.preventDefault();

        this.props.getClients();
        // this.props.createClient({id: 'ECU'});
    }
}

const mapStateToProps = (state) => {
    console.log(state)
    return {
        sectors: state.firestore.ordered.sectors,
        clients: state.client.clients
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createClient: (client) => dispatch(createClient(client)),
        getClients: () => dispatch(getClients())
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(() => [
        {
            collection: 'sectors',
            doc: 'athletics',
            subcollections: [
                { collection: 'clients'}
            ]
        }
    ]
    )
)(Clients);

var clientTable = {
    padding: '40px',
    backgroundColor: 'white',

};

var headerBar = {
    padding: '10px',
    fontFamily: 'Roboto',
    fontSize: '20px'
}

var menuButton = {
    fontSize: '20px',
    color: 'black',
    backgroundColor: 'white',
    borderColor: 'lightgray',
    borderStyle: 'solid'
}
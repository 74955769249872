import React from 'react';
import firebase from 'firebase';
import {Card, Col, Form, Row, InputGroup, Button} from 'react-bootstrap';
import {getClientDetail} from '../Firebase'
import moment from 'moment';

import {DateRangePicker, SingleDatePicker, DayPickerRangeController} from 'react-dates';

const db = firebase.firestore();

class ClientDetail extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            sumMaterialCost: 0,
            sumShippingCost: 0,
            sumTotalCost: 0,
            sumProductRevenue: 0,
            sumShippingRevenue: 0,
            sumTotalRevenue: 0,
            sumNetProfit: 0,
            sumModelingTime: 0,
            sumResinAmounts: {},
            clientID: '',
            orderCount: '',
            athleteCount: '',

            startDate: null,
            endDate: null,
            focusedInput: null
        }

        this.getClientDetail = getClientDetail.bind(this)
    }

    componentDidMount() {
        console.log('mounted Client Detail with this detail')
        // load client details
        console.log(this.props.location.state.clientInfo)
        //this.unsubRef = this.getClientDetail(this.props.location.state.clientInfo.id)
        this.loadClientInfoFromParent();
    }

    componentWillUnmount() {
        //this.stopListener(this.unsubRef)
    }

    loadClientInfoFromParent = () => {
        this.setState({
            clientID: this.props.location.state.clientInfo.id,
            orderCount: this.props.location.state.clientInfo.orderCount,
            athleteCount: this.props.location.state.clientInfo.athleteCount
        })
    }

    render() {  

        console.log('rendering Client Detail with this state:')
        console.log(this.state)

        return (
            <div>
                <Card style={infoCard}>
                    <Col>
                    <Row><h2>{this.state.clientID} Info</h2></Row>
                    <DateRangePicker
                        startDateId = 'startDate'
                        endDateId = 'endDate'
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        onDatesChange={({ startDate, endDate }) => { this.setState({startDate, endDate})}}
                        focusedInput={this.state.focusedInput}
                        onFocusChange={(focusedInput) => {this.setState({focusedInput})}}
                        isOutsideRange={() => false}
                    
                    />
                    <Button onClick={this.getClientDataForRange.bind(this)}>Get data</Button>
                    <Col>
                        <Form className="mb-3">

                                <Form.Label>Order Count</Form.Label>
                                <Form.Control readOnly value={this.state.orderCount}/>

                                <Form.Label>Athlete Count</Form.Label>
                                <Form.Control readOnly value={this.state.athleteCount}/>

                                <Form.Label>Total Modeling Time</Form.Label>
                                <InputGroup>
                                <Form.Control readOnly value={this.state.sumModelingTime}/>
                                    <InputGroup.Append><InputGroup.Text>minutes</InputGroup.Text></InputGroup.Append>
                                </InputGroup>
                                
                                <Form.Label>Total Resin Amounts</Form.Label>
                                <InputGroup>
                                    <Form.Control readOnly value={JSON.stringify(this.state.sumResinAmounts)}/>
                                    <InputGroup.Append><InputGroup.Text>mLs</InputGroup.Text></InputGroup.Append>
                                </InputGroup>


                            <Row>
                                <Col>
                                    <Form.Label>Product Revenue Cost</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                        <Form.Control readOnly value={this.state.sumProductRevenue}/>   
                                    </InputGroup>
                                    <Form.Label>Shipping Revenue</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                        <Form.Control readOnly value={this.state.sumShippingRevenue}/>
                                    </InputGroup>
                                    
                                    <Form.Label>Total Revenue</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                        <Form.Control readOnly value={this.state.sumTotalRevenue}/>
                                    </InputGroup>
                                    
                                </Col>
                                <Col>
                                    <Form.Label>Material Cost</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                        <Form.Control readOnly value={this.state.sumMaterialCost}/>
                                    </InputGroup>
                                    
                                    <Form.Label>Shipping Cost</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                        <Form.Control readOnly value={this.state.sumShippingCost}/>
                                    </InputGroup>
                                    
                                    <Form.Label>Total Cost</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                        <Form.Control readOnly value={this.state.sumTotalCost}/>
                                    </InputGroup>
                                    
                                </Col>
                            </Row>
                            <Form.Label>Net Profit</Form.Label>
                            <InputGroup>
                                <InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                <Form.Control readOnly value={this.state.sumNetProfit}/>    
                            </InputGroup>                                                            
                        </Form> 
                    </Col>
                    </Col>
                </Card>
            </div>
        )
    }

    stopListener = (unsubRef) => {
        unsubRef();
    }

    getClientDataForRange() {

        if ((this.state.startDate != null) && (this.state.endDate != null)) {
            var startDate = this.state.startDate._d
            var endDate = this.state.endDate._d
            this.getClientDetail(this.props.location.state.clientInfo.id, startDate, endDate)
        }
    }
}


export default ClientDetail;

var infoCard = {
    display: 'flex',
    flexDirection: 'row'
}
import React from 'react';
import firebase from 'firebase'
import logo from '../assets/fullLogo.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Card, Button, Form, Row, Col, Container} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import styles from '../'

class Landing extends React.Component{

    constructor(props) {
        super(props);
        this.inputEmail = React.createRef();
        this.inputPassword = React.createRef();
    }
    
    render(){

        return(
            <div style={mainPage}>
            <Container style={vert}>
                    <Card className = "text-center" style={landingCard}>
                        <Card.Img variant="top" src={logo}/>
                        <Card.Title>
                            <h1>Welcome to 3D printed athletic protective devices!</h1>
                        </Card.Title>
                        <Card.Text>
                            <a href="www.protect3dpads.com">Lost? Please click here to visit our public site to learn more.</a>
                        </Card.Text>
                    </Card>

                    <Card style={signInCard}>
                        <Card.Header>Sign In</Card.Header>
                        <Card.Body>
                            <Form onSubmit={this.handleSignIn.bind(this)}>
                                <Form.Group controlID="email">
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control type="email" placeholder="Enter email" ref={this.inputEmail}/>
                                </Form.Group>
                                <Form.Group controlID="password">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" placeholder="Password" ref={this.inputPassword}/>
                                </Form.Group>
                                <Button variant="primary" type="submit" onClick={this.checkUser}>Submit</Button>
                            </Form>
                        </Card.Body>
                    </Card>
            </Container>
            </div>
        );
    }

    async handleSignIn(event) {

        console.log('event triggered');
        event.preventDefault();
    
        try{
    
            var mEmail = this.inputEmail.current.value;
    
            console.log(mEmail);
    
            const user = await firebase
                .auth()
                .signInWithEmailAndPassword(this.inputEmail.current.value, this.inputPassword.current.value).catch(function(error){
                    alert(error);
                });
            console.log('passed sign in');
            } catch (error) {
                alert(error);
            }

            console.log(this.props)
            console.log(this.props.history)
    
        this.props.history.push('/home');
    };
    
    checkUser(){
        console.log('func triggered');
        //var currentUser = firebase.auth.currentUser;
    
        firebase.auth().onAuthStateChanged(function(user){
            if (user){
                console.log(user.email)
            } else{
                console.log('no user')
            }
        })
    };
}

export default withRouter(Landing);

var signInCard = {
    padding: '40px',
    width: '50%'
}

var mainPage ={
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%'
}

var landingCard = {
    padding: '40px',
    alignItems: 'center',
    width: '50%',
}

var mainPage ={
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
}

var vert ={
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}
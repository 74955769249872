import { getFirestore } from 'redux-firestore';
import { array } from 'prop-types';
import { getResinFactor } from '../utils/Utils';

import { addToast } from '../actions/toastActions';

const INITAL_STATE = {
    currentOrderID: {},
}

const orderReducer = (state = INITAL_STATE, action) => {
    switch (action.type) {

        case 'GET_ORDERS': {
            console.log(action)

            console.log(action.payload)

            let mOrders = action.payload.map(doc => {
                return doc.data();
            })

            console.log(mOrders)
            return {
                ...state,
                orders: mOrders
            }
        }
        case 'SET_CURRENT_ORDER': {
            console.log('setting current order')
            console.log(action)
            //var currentOrderID = action.order.padUniqueID;
            return {
                ...state,
                curOrder: action.updatedOrder
            }
        }
        case 'SET_ORDER_LISTENER': {
            console.log('setting order listener')
            console.log(action)
            var orderID = action.orderID;
            const firestore = getFirestore();
            firestore.setListener({
                collectionGroup: 'orders',
                where: ['padUniqueID', '==', {orderID}]
            })
        }
        case 'HANDLE_ORDER_DETAIL_CHANGE': {
            console.log(action)
            console.log(action.payload.scope)
            console.log(action.payload.index)

            const id = action.payload.id;
            const value = action.payload.value;
            const scope = action.payload.scope;
            const index = action.payload.index;
            
            // do something different depending on whether change was in top-level order detail, versions arr, or prints arr

            switch (scope) {

                // change was in versions arr 
                case 'versions': {
                    let newVersions = {...state.curOrder.versions};

                    let newVersionArr = Object.values(newVersions).map((version, i) => {
                        if (i !== index) {
                            return version
                        }
                        let newVersion = {...version, [id]: value}
                        return newVersion;
                    })

                    return { 
                        ...state,
                        curOrder: {
                            ...state.curOrder,
                            versions: newVersionArr
                        }
                    }
                }

                // top-level order detail result
                default:
                    return {
                        ...state,
                        curOrder: {
                            ...state.curOrder,
                            [id]: value
                        }
                    
                }
            }
        }

        case 'HANDLE_VERSION_CHANGE': {
            const id = action.payload.id;
            const value = action.payload.value;
            const index = action.payload.versionIndex;

            let newVersions = {...state.curOrder.versions};

            newVersions = Object.values(newVersions).map((version, i) => {
                console.log('indices: '+index+' '+i)
                if (i !== index) {
                    console.log(version)
                    return version
                }
                let newVersion = {...version, [id]: value}
                console.log(newVersion)
                return newVersion;
            });
            
            return {
                ...state,
                curOrder: {
                    ...state.curOrder,
                    versions: newVersions
                }
            }
        }


        case 'HANDLE_PRINT_CHANGE': {
            const id = action.payload.id;
            const value = action.payload.value;
            const versionIndex = action.payload.versionIndex;
            const printIndex = action.payload.printIndex;


            let newVersions = {...state.curOrder.versions};
            console.log(newVersions)

            newVersions = Object.values(newVersions).map((version, i) => {
                if (i !== versionIndex) {
                    return version
                }
                let newPrints = {...version}.prints
                console.log(newPrints)

                newPrints = Object.values(newPrints).map((print, j) => {
                    if (j !== printIndex) {
                        return print
                    }
                    let newPrint = {...print, [id]: value}
                    console.log(newPrint)

                    return newPrint
                })
                let newVersion = {...version, prints: newPrints}
                console.log(newVersion)

                return newVersion;
            });

            return {
                ...state,
                curOrder: {
                    ...state.curOrder,
                    versions: newVersions
                }
            }
        }

        case 'UPDATE_MATERIAL_COSTS': {
            let versions = {...state.curOrder.versions};
            const index = action.versionIndex;

            let resinAmount = versions[index].resinAmount;
            console.log(resinAmount)

            let prints = versions[index].prints;

            prints.forEach(print => {
                let resinType = print.resinType;
                let resinFactor = getResinFactor(resinType);
                let materialCost = parseFloat(Number(resinAmount)*Number(resinFactor)).toFixed(2)
                console.log(materialCost)

                print.materialCost = materialCost
            })

            console.log(versions)

            return {
                ...state,
                curOrder: {
                    ...state.curOrder,
                    versions: Object.values(versions)
                }
            }
        }

        case 'UPDATE_FINANCIAL_INFO': {
            // get total material costs from each version-print
            let materialCost = 0;
            let versions = [...state.curOrder.versions];
            // let versions = curOrder.versions;

            versions.forEach(version => {
                let prints = version.prints;
                prints.forEach(print => {
                    let printMaterialCost = print.materialCost;
                    materialCost += Number(printMaterialCost);
                })
            })

            console.log({...state.curOrder}.shippingCost)
            let totalCost = Number(materialCost) + Number({...state.curOrder}.shippingCost)
            let totalRevenue = Number({...state.curOrder}.productRevenue) + Number({...state.curOrder}.shippingRevenue)
            let netProfit = Number(totalRevenue) - Number(totalCost)

            return {
                ...state,
                curOrder: {
                    ...state.curOrder,
                    materialCost: materialCost.toFixed(2),
                    totalCost: totalCost.toFixed(2),
                    totalRevenue: totalRevenue.toFixed(2),
                    netProfit: netProfit.toFixed(2)
                }
            }
        }

        case 'ADD_NEW_DESIGN_VERSION': {

            // if versions array has not been created, add an empty one
            console.log(state.curOrder.versions)
            if (state.curOrder.versions == undefined) {
                state.curOrder.versions = [];
            }

            // add new version object to copy of current versions array
            return {
                ...state,
                curOrder: {
                    ...state.curOrder,
                    versions: [...state.curOrder.versions].concat({
                        modelingTime: '',
                        resinAmount: '',
                        postModelingCheckoff: '',
                        postModelingCheckoffDate: '',
                        postModelingCheckoffUser: '',
                        prints: [],
                        versionDescription: ''
                    })
                }
            }
        }

        case 'DELETE_DESIGN_VERSION': {

            // delete version that matches action index
            return {
                ...state,
                curOrder: {
                    ...state.curOrder,
                    versions: state.curOrder.versions.filter((version, i) => {
                        return i !== action.versionIndex;
                    })
                }
            }
        }

        case 'ADD_NEW_PRINT': {

            const versionIndex = action.versionIndex;

            // add new print
            return {
                ...state,
                curOrder: {
                    ...state.curOrder,
                    versions: state.curOrder.versions.map((version, i) => {
                        if (i !== versionIndex) {
                            return version
                        }
                        let newPrints = {...version}.prints.concat({
                            resinType: '',
                            materialCost: '',
                            prePringCheckoff: '',
                            prePrintCheckoffDate: '',
                            prePringCheckoffUser: '',
                            postPrintCheckoff: '',
                            postPrintCheckoffDate: '',
                            postPrintCheckoffUser: '',
                            postProcessingCheckoff: '',
                            postProcessingCheckoffDate: '',
                            postProcessingCheckoffUser: '' 
                        })
                        let newVersion = {...version, prints: newPrints}
                        return newVersion
                    })
                }
            }
        }

        case 'DELETE_PRINT': {

            const versionIndex = action.payload.versionIndex;
            const printIndex = action.payload.printIndex;

            // delete print
            return {
                ...state,
                curOrder: {
                    ...state.curOrder,
                    versions: state.curOrder.versions.map((version, i) => {
                        if (i !== versionIndex) {
                            return version
                        }
                        let newPrints = {...version}.prints.filter((print, j) => {
                            return j !== printIndex
                        })
                        console.log(newPrints)
                        let newVersion = {...version, prints: newPrints}
                        return newVersion
                    })
                }
            }
        }

        case 'SAVE_ORDER_DETAIL': {
            console.log('in reducer after saving order')
        }

        default:
            return state;
    }
}

export default orderReducer;
import React from 'react';
import { Toast } from 'react-bootstrap';
import { connect } from 'react-redux';
import { removeToast } from '../actions/toastActions';

class MyToast extends React.Component {
    render () {
        console.log(this.props)
        return (
            <Toast style={toast} onClose = {this.testRemoveToast}>
                <Toast.Header>
                    <strong className='mr-auto'>{this.props.title}</strong>
                </Toast.Header>
                <Toast.Body>
                    {this.props.body}
                </Toast.Body>
            </Toast>
        )
    }

    shouldComponentUpdate() {
        return false;
    }

    testRemoveToast = () => {

        console.log('Pressed remove toast', this.props.id)
        this.props.removeToast(this.props.id)
    }
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        removeToast: (id) => dispatch(removeToast(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyToast);

var toast = {
    position: 'absolute',
    top: 50,
    right: 50,
    width: '500px',
    zIndex: 10,
}
import React from 'react';
import firebase from './Firebase';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { appendToMemberExpression } from '@babel/types';
import { func } from 'prop-types';

const db = firebase.firestore();

class Manage extends React.Component {
    constructor (props) {
        super (props);

        this.inputEmail = React.createRef();
        this.inputPassword = React.createRef();
        this.inputUID = React.createRef();
        this.inputSector = React.createRef();
        this.inputClient = React.createRef();
        this.inputTeam = React.createRef();
        this.inputDefaultSport = React.createRef();
        this.inputName = React.createRef();
        this.inputRole = React.createRef();

    };

    render () {

        return (
            <div style={mainPage}>

                <Button onClick={this.testMondayAdd}>Test Monday Add</Button>

                <Container style={manageContainer}>
                    <Row>
                        <Col>
                            <h1 className='text-center'>Manage</h1>
                        </Col>
                    </Row>                

                    <h4>Input all information then press submit</h4>
                    
                    
                    <Form onSubmit={this.addUser.bind(this)}>
                        <Form.Group controlID='Email'>
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type='email' placeholder='timothy.skapek@duke.edu' ref={this.inputEmail}/>
                        </Form.Group>

                        <Form.Group controlID='Password'>
                            <Form.Label>Password</Form.Label>
                            <Form.Control type='text' placeholder='gobluedevils' ref={this.inputPassword}/>
                        </Form.Group>

                        <Form.Group controlID='UID'>
                            <Form.Label>Unique ID</Form.Label>
                            <Form.Control type='text' placeholder='BXnX6OEseBcxInFgEvmIZ2YFOxR2' ref={this.inputUID}/>
                        </Form.Group>

                        <Form.Group controlID='Name'>
                            <Form.Label>Full name</Form.Label>
                            <Form.Control type='text' placeholder='Tim Skapek' ref={this.inputName}/>
                        </Form.Group>

                        <Form.Group controlID='Sector'>
                            <Form.Label>Sector</Form.Label>
                            <Form.Control as='select' ref={this.inputSector}>
                                <option>athletics</option>
                                <option>medical</option>
                                <option>defense</option>
                                <option>commercial</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlID='Client'>
                            <Form.Label>Client</Form.Label>
                            <Form.Control type='text' placeholder='Duke University' ref={this.inputClient}/>
                        </Form.Group>

                        <Form.Group controlID='Team'>
                            <Form.Label>Team</Form.Label>
                            <Form.Control type='text' placeholder='Football' ref={this.inputTeam}/>
                        </Form.Group>

                        <Form.Group controlID='Default Sport'>
                            <Form.Label>Default sport</Form.Label>
                            <Form.Control type='text' placeholder='Football' ref={this.inputDefaultSport}/>
                        </Form.Group>

                        <Form.Group controlID='Role'>
                            <Form.Label>Role</Form.Label>
                            <Form.Control as='select' ref={this.inputRole}>
                                <option>user</option>
                                <option>admin</option>
                            </Form.Control>
                        </Form.Group>

                        <Button variant='primary' type='submit'>
                            Submit
                        </Button>
                    </Form>
                    </Container>
            </div>
        );
    }

    // async moveOrders(){

    //     console.log('starting move');

    //     var oldRef = db.collection('teams').doc('Testing').collection('athletes');
    //     var newRef = db.collection('archivedScans').doc('Testing').collection('archivedAthletes');

    //     // for each order in old location
    //     oldRef.get()
    //     .then(querySnapshot => {
            
    //         querySnapshot.forEach(function(doc) {

    //             // get info
    //             var uniqueID = doc.id;
    //             var docData = doc.data();

    //             console.log(doc.id);

    //             // create new order in new location
    //             newRef.doc(uniqueID).set(docData).then(function() {
    //                 console.log('successfully made new doc');

    //                 console.log('after delete');
    //             });
    //         })
    //     }).catch(err => {
    //         console.log('Error getting documents', err);
    //     });

    //     console.log('finished move')
    // }

    async addUser(event) {

        console.log('add user triggered');
        event.preventDefault();

        try{

            console.log('trying to get email')
            var mEmail = this.inputEmail.current.value;
            console.log('got email input')
            var mPassword = this.inputPassword.current.value;
            console.log('here')
            var mUID = this.inputUID.current.value;

            var mSector = this.inputSector.current.value;
            var mClient = this.inputClient.current.value;
            var mTeam = this.inputTeam.current.value;
            var mDefaultSport = this.inputDefaultSport.current.value;
            var mName = this.inputName.current.value;
            var mRole = this.inputRole.current.value;

            console.log('got all values');

            // add user in firestore
            db.collection('users').doc(mUID).set({
                client: mClient,
                team: mTeam,
                role: mRole,
                name: mName,
                email: mEmail,
                uid: mUID,
                defaultSport: mDefaultSport,
                sector: mSector
            })
            .then(function() {
                console.log("User added to firestore successfully");
            })
            .catch(function(error) {
                console.error("Error adding user: ", error);
            })

        } catch (error) {
            alert(error);
        }
    }

    testMondayAdd = () => {
        console.log('Starting monday func')

        var dummyData = {
            designModificationComments: 'Extra large',
            name: 'Tim Skapek',
            orderClient: 'University of Southern California',
            number: '1',
            deviceType: 'Hinged Wrist Brace',
            preferredDeliveryDate: 'Saturday (09/07)',
            timestamp: '2021-09-01'
        };

        // format client identifier
        var splitClient = dummyData.orderClient.split(' ')
        var clientID = ''
        splitClient.forEach(str => {
            var firstLetter = str.split('')[0]
            clientID += firstLetter
        })

        // format athlete identifier
        var firstLast = dummyData.name.split(' ')
        var athID = firstLast[0].split('')[0]+firstLast[1].split('')[0]+dummyData.number

        // format submission date
        const today = new Date()
        const mm = today.getMonth()+1
        const dd = today.getDate()
        const yyyy = today.getFullYear()
        const subDate = yyyy+'-'+mm+'-'+dd

        // format preferred delivery date
        let deliverMMDD = dummyData.preferredDeliveryDate.split(' ').pop().replace('(','').replace(')','').split('/')
        const deliverDate = yyyy+'-'+deliverMMDD[0]+'-'+deliverMMDD[1]

        // GraphQL query
        let query = "mutation ($myItemName: String!, $columnVals: JSON!) { create_item (board_id: 1640386020, group_id: \"topics\", item_name: $myItemName, column_values: $columnVals) { id }}";
    
        // GraphQL vars
        let vars = {
            'myItemName': clientID+' | '+dummyData.deviceType+' | '+athID,
            'columnVals': JSON.stringify({
                'status': {'label':'Submitted'},
                'timeline': {'from':subDate,'to':deliverDate},
                'text': dummyData.designModificationComments
            })
        }
    
        // monday.com API request
        fetch("https://api.monday.com/v2",{
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjEyMzAwNDU4MSwidWlkIjoyMjA0OTAxMSwiaWFkIjoiMjAyMS0wOS0wMVQyMDoxOToxMS4wMDBaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6ODk2MTc0OCwicmduIjoidXNlMSJ9.BM_q0cL_T8NIFjeQeHeaQxysi9tYGmv9I02rczJtm0k'
            },
            body: JSON.stringify({
                query: query,
                variables: JSON.stringify(vars)
            })
        })
        .then(res => res.json())
        .then(res => console.log(JSON.stringify(res, null, 2)));
    }

}

export default Manage;

var manageContainer = {
    padding: '40px',
    width: '75%'
}

var mainPage ={ 
    display: 'flex',
    width: '100%',
    height: '100%'
}
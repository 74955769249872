import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import SignUpView from './SignUpView';
import firebase from '../Firebase';

class SignUpContainer extends Component {
    
    handleSignUp(event) {

        console.log('here');
        event.preventDefault();

        

        const {email, password} = event.target.elements;
        
        try {
            console.log('right before user');
            const user = firebase
                .auth()
                .createUserWithEmailAndPassword(email.value, password.value).catch(function(error){
                    alert(error);
                });

            console.log('after creating user');
            //this.props.history.push('/');
        } catch (error) {
            alert(error);
        }
    };

    render() {
        return <SignUpView onSubmit={this.handleSignUp}/>;
    }
}

export default withRouter(SignUpContainer);
import React from  'react';
import firebase from '../Firebase';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Card, Button, Form} from 'react-bootstrap';


const SignInView = ({onSubmit}) => {

    return (
        <div style={mainPage}>
            <Card style={signInCard}>
                <Card.Header>Sign In</Card.Header>
                <Card.Body>
                    <Form onSubmit={onSubmit}>
                        <Form.Group controlID="email">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control type="email" placeholder="Enter email"/>
                        </Form.Group>
                        <Form.Group controlID="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password"/>
                        </Form.Group>
                        <Button variant="primary" type="submit" onClick={checkUser.bind(this)}>Submit</Button>
                    </Form>
                </Card.Body>
            </Card>
        </div>
    );
};



export default SignInView;

function checkUser(){
    console.log('func triggered');
    //var currentUser = firebase.auth.currentUser;

    firebase.auth().onAuthStateChanged(function(user){
        if (user){
            console.log(user.email)
        } else{
            console.log('no user')
        }
    })
};

var signInCard = {
    padding: '40px',
    width: '50%'
}

var mainPage ={
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width:'100%',
    height: '100%'
}
import React from 'react';
import firebase from '../Firebase';
import { withRouter } from 'react-router-dom';
import { render } from '@testing-library/react';
import { Card, Row, Col, Button, Nav } from 'react-bootstrap';

import {decodeOrderStatus, decodeInternalStatus} from '../../utils/Utils'

import { connect } from 'react-redux';
import { setCurrentOrder } from '../../actions/orderActions';

class Order extends React.Component{
    constructor(props){
        super(props);

        this.loadOrderDetail = this.loadOrderDetail.bind(this);
        this.decodeOrderStatus = decodeOrderStatus.bind(this);
        this.decodeInternalStatus = decodeInternalStatus.bind(this);
    }

    render(){
        // Set reference to data passed from parent
        const orderInfo = this.props.dataFromParent;

        console.log(orderInfo)

        return(
            <Card style={orderStyle}>
                <Row>
                    <Col>{orderInfo.name}</Col>
                    <Col>{orderInfo.orderClient}</Col>
                    <Col>{orderInfo.orderTeam}</Col>
                    <Col>{orderInfo.position}</Col>
                    <Col>{orderInfo.number}</Col>
                    <Col>{orderInfo.padType}</Col>
                    <Col>{this.decodeOrderStatus(orderInfo.orderStatus)}</Col>
                    <Col>{this.decodeInternalStatus(orderInfo.internalStatus)}</Col>
                    <Col>
                        <Button href={orderInfo.zipLink}>Download</Button>
                    </Col>
                    <Col>
                        {/* <Nav>
                            <Nav.Link href="/detail">Detail</Nav.Link>
                        </Nav> */}
                        <Button onClick={this.newLoadOrderDetail}>Detail</Button>
                    </Col>
                </Row>
            </Card>
            );

    }

    // set this orderInfo as info for currentOrder in Redux store

    newLoadOrderDetail = (event) => {
        console.log(this.props)

        var order = this.props.dataFromParent;
        // handle current Client, Team (should now be done using Redux)

        console.log(order)

        this.props.setCurrentOrder(order.padUniqueID)
        
        console.log(order)
        console.log(order.padUniqueID)
        this.props.history.push('/order#'+order.padUniqueID)
    }

    async loadOrderDetail(event) {

        //var test = this.props.testData;
        console.log(this.props)

        var orderInfo = this.props.dataFromParent;

        if (this.props.location.pathname == '/orders') {
            this.props.saveClientInfo(orderInfo.client, orderInfo.team);
        }
        
        console.log(orderInfo);

        this.props.history.push('/detail#'+orderInfo.name, {orderInfo: orderInfo})
    }
}

const mapStateToProps = (state) => {
    return {}
}
const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentOrder: (uniqueID) => dispatch(setCurrentOrder(uniqueID))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Order));

var orderStyle = {
    padding: '10px',
    borderStyle: 'none',
    fontFamily: 'Roboto',
    fontSize: '20px'
}
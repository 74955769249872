import React from 'react';
import firebase from 'firebase'
import 'bootstrap/dist/css/bootstrap.min.css';
import shield from '../assets/shieldLogo.png';
import { Container, Row, InputGroup, Col, Button, ListGroup, Card, Spinner } from 'react-bootstrap';
import Order from './Orders/Order';

import {getOpenOrders} from './Firebase';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';

const db = firebase.firestore();

class Home extends React.Component{

    constructor(props) {
        super(props);
    }

    render() {

        //console.log(this.props)
        // If data has not been loaded, return blank Div
        if (!this.props.orders) {
            return <Spinner animation="border" variant="primary"/>
        }
        console.log(this.props)
        const sortedOrders = Object.values(this.props.orders);
        //console.log(sortedOrders)
        const listItems = sortedOrders.map((d) => {

            // filter for 2021 products, OR University of Louisville
            if (d.yearOrdered == '2021' || d.orderClient == 'University of Louisville' || d.yearOrdered == '2022'){
                
                // filter out based on order status, and remove Protect3d orders
                if (d.orderStatus != 'Shipped' 
                    && d.orderStatus != 'Received' 
                    && d.orderClient != 'Protect3d'
                    && d.orderClient != null) {
                    console.log(d.orderStatus)
                    return <Order dataFromParent = {d}/>
                }
            }
        });

        return(
            <div style={mainPage}>
                
                <Container>
                    <Row> <h1>Open Orders</h1></Row>

                    <div syle={orderTable}>

                        <Card style={headerBar}>
                            <Row>
                            <Col>
                                <Button onClick={mySort(sortedOrders,'name')} style={menuButton}>Name</Button>
                            </Col>
                            <Col>
                                <Button onClick={mySort(sortedOrders,'client')} style={menuButton}>Client</Button>
                            </Col>
                            <Col>
                                <Button onClick={mySort(sortedOrders,'team')} style={menuButton}>Team</Button>
                            </Col>
                            <Col>
                                <Button onClick={mySort(sortedOrders,'position')} style={menuButton}>Position</Button>
                            </Col>
                            <Col>
                                <Button onClick={mySort(sortedOrders,'number')} style={menuButton}>Number</Button>
                            </Col>
                            <Col>
                                <Button onClick={mySort(sortedOrders,'padType')} style={menuButton}>Device Type</Button>
                            </Col>
                            <Col>
                                <Button style={menuButton}>Order Status</Button>
                            </Col>
                            <Col>Download Scan</Col>
                            <Col>Order Detail</Col>

                            </Row>
                        </Card>
                        <ListGroup>{listItems}</ListGroup>

                    </div>


                </Container>

            </div>
        )
    }
}

function mySort(arr, key){
    arr.sort((a,b) => (a[key] > b[key]) ? 1 : -1);
}

const mapStateToProps = (state) => {
    console.log(state)
    return {
        orders: state.firestore.data.orders,
    }
}

// TODO: make this orders listener grab new orders whose internalStatus is not yet created

export default compose(
    connect(mapStateToProps), 
    firestoreConnect(() => [
    {
        collectionGroup: 'orders',
        // where: [
        //     ['yearOrdered', '==', '2021']        ],
        orderBy: [
            ['timestamp', 'desc']
        ],
    }
]))(Home);


var mainPage ={
    display: 'flex',
    flexDirection: 'column',

    width: '100%',
    height: '100%',
}

var logo ={
    height: '300px',
    width: '300px'
}

var orderTable = {
    padding: '40px',
    backgroundColor: 'white',

};

var headerBar = {
    padding: '10px',
    fontFamily: 'Roboto',
    fontSize: '20px'
}

var menuButton = {
    fontSize: '20px',
    color: 'black',
    backgroundColor: 'white',
    borderColor: 'lightgray',
    borderStyle: 'solid'
}
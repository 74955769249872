import React from 'react';
import firebase from '../Firebase';
import { withRouter } from 'react-router-dom';
import { Card, Row, Col, Button, Nav } from 'react-bootstrap';

class Client extends React.Component{
    constructor(props) {
        super(props);

        this.loadClientDetail = this.loadClientDetail.bind(this);
    }

    componentDidMount() {
        this.loadClientInfo()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.dataFromParent !== this.props.dataFromParent) {
            this.loadClientInfo()
        }
    }
    
    render() {
        //console.log(this.props)
        const dataRef = this.props.dataFromParent;
        // console.log(dataRef)
        const id = dataRef.id
        const count = dataRef.orderCount
        // console.log(dataRef)
        // console.log(Object.keys(dataRef))
        console.log(JSON.stringify(dataRef))

        return (
            <Card style={clientStyle}>
                <Row>
                    <Col>{this.props.dataFromParent.id}</Col>
                    <Col>{this.props.dataFromParent.orderCount}</Col>
                    <Col>{this.props.dataFromParent.athleteCount}</Col>
                    <Col>
                        <Button onClick={this.loadClientDetail}>Detail</Button>
                    </Col>
                </Row>
            </Card>
        )
    }

    loadClientInfo = () => {
        const dataRef = this.props.dataFromParent;
        var test = Array.from(dataRef)
    }

    async loadClientDetail(event) {
        var clientInfo = this.props.dataFromParent;

        this.props.history.push('/client#'+clientInfo.id, {clientInfo: clientInfo})
    }
    
}

export default withRouter(Client);

var clientStyle = {
    padding: '10px',
    borderStyle: 'none',
    fontFamily: 'Roboto',
    fontSize: '20px'
}
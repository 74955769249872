import React from 'react';
import firebase from 'firebase';
import {Card, Form, Col, Button, InputGroup, ListGroup, Row, DropdownButton, Dropdown, ButtonGroup, Badge} from 'react-bootstrap';
import { version } from 'react-dom';
import { FormRow } from 'react-bootstrap/Form';
import {getCurrentUser} from '../Firebase';
import {getResinFactor, getUserTimestamp} from '../../utils/Utils'

import { connect } from 'react-redux';
import { handleVersionChange, handlePrintChange, updateMaterialCosts, updateFinancialInfo, deleteDesignVersion, addNewPrint, deletePrint } from '../../actions/orderActions';


class DesignVersion extends React.Component{
    constructor(props){
        super(props);

        this.modelingTimeInput = React.createRef();
        this.resinTypeInput = React.createRef();
        this.versionDescriptionInput = React.createRef();
        this.resinAmountInput = React.createRef();

        this.getCurrentUser = getCurrentUser.bind(this)
    }

    render() {
        console.log('rendering version')
        const printList = this.props.dataFromParent.prints;
        var listItems = printList.map((d, index) => <Print 
                                                    dataFromParent = {d} 
                                                    printIndex={index} 
                                                    versionIndex = {this.props.versionIndex}
                                                    deletePrint = {this.deletePrint}
                                                    handlePrintChange = {this.props.handlePrintChange}
                                                    updateMaterialCosts = {this.props.updateMaterialCosts}
                                                    updateFinancialInfo = {this.props.updateFinancialInfo}
                                                    />);

        const resinAmount = this.props.dataFromParent.resinAmount
        const modelingTime = this.props.dataFromParent.modelingTime
        const versionDescription = this.props.dataFromParent.versionDescription

        return(
            <Card style={infoCard}>
                <Col>
                    <Row>
                        <Col><h5>Version {this.props.versionIndex+1}</h5></Col>
                        <Col><Button variant="outline-danger" onClick={this.deleteVersion.bind(this)}>Delete Version</Button></Col>
                    </Row>
                    <Form>
                        <Row>
                                <Col><Form.Label>Version Description</Form.Label></Col>
                                <Col><Form.Control
                                    ref = {this.versionDescriptionInput}
                                    value = {versionDescription}
                                    id = 'versionDescription'
                                    onChange = {this.handleChange.bind(this)}
                                    /></Col>
                        </Row>
                        <Row>
                            <Col><Form.Label>Modeling Time</Form.Label></Col>
                            <Col><InputGroup>
                                <Form.Control
                                    ref = {this.modelingTimeInput}
                                    value = {modelingTime}
                                    id = 'modelingTime'
                                    onChange = {this.handleChange.bind(this)}
                                    />
                                <InputGroup.Append>
                                    <InputGroup.Text>minutes</InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup></Col>
                        </Row>
                        <Row>
                            <Col><Form.Label>Resin Amount</Form.Label></Col>
                            <Col><InputGroup>
                                <Form.Control
                                    ref = {this.resinAmountInput}
                                    value = {resinAmount}
                                    id = 'resinAmount'
                                    onChange = {this.handleChange.bind(this)}
                                    />  
                                <InputGroup.Append>
                                    <InputGroup.Text>mL</InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup></Col>
                        </Row>  
                        <Row>
                            <Col><Form.Label>Is digital design approved? 
                                <Badge pill variant="dark">{this.props.dataFromParent.postModelingCheckoff+' '
                                +this.props.dataFromParent.postModelingCheckoffUser+' '
                                +this.props.dataFromParent.postModelingCheckoffDate}</Badge></Form.Label></Col>
                            <Col><ButtonGroup>
                            <Button 
                                variant="secondary"
                                name="postModelingCheckoff-Yes"
                                onClick={this.handleButtonGroupChange.bind(this)}>Yes</Button>
                            <Button 
                                variant="secondary"
                                name="postModelingCheckoff-No"
                                onClick={this.handleButtonGroupChange.bind(this)}>No</Button>
                            </ButtonGroup></Col>
                        </Row>
                    </Form>

                    <Row>
                        <Col><h5>Printing</h5></Col>
                        <Col><Button variant="outline-primary" onClick={this.addPrint}>Add Print</Button></Col>
                    </Row>

                    <ListGroup>{listItems}</ListGroup>

                </Col>

            </Card>

        )
    }

    // delete design version
    deleteVersion() {
        console.log('deleting version '+this.props.versionIndex)
        this.props.deleteDesignVersion(this.props.versionIndex)
    }

    // handle change in design version info
    handleChange(event) {
        event.persist()
        console.log(this.props.versionIndex)

        console.log(event.target.id+' '+event.target.value)  
        this.props.handleVersionChange(event.target.id, event.target.value, this.props.versionIndex)

        if (event.target.id == 'resinAmount') {
            console.log('doing something because resin amount change')
            this.props.updateMaterialCosts(this.props.versionIndex)
            this.props.updateFinancialInfo()
        }

    }
    
    // handle change in design version button group
    handleButtonGroupChange = (event) => {
        event.persist()
        const userTimestamp = getUserTimestamp()
        console.log(userTimestamp)

        const baseID = event.target.name.split('-')[0];
        const result = event.target.name.split('-')[1];
        const index = this.props.versionIndex;

        // TODO: Adjust orderReducer to handle complex actions so we only have to fire one of these

        this.props.handleVersionChange(baseID, result, index)
        this.props.handleVersionChange(baseID+'User', userTimestamp[1], index)
        this.props.handleVersionChange(baseID+'Date', userTimestamp[0], index)

    }

    // add print to design version
    addPrint = () => {
        console.log(this.props.versionIndex)
        this.props.addNewPrint(this.props.versionIndex)
    }

    // delete print from design version
    deletePrint = (printIndex) => { 
        console.log('properly deleting print')
        this.props.deletePrint(this.props.versionIndex, printIndex);
    }
}

class Print extends React.Component{
    constructor(props){
        super(props)

        this.resinTypeInput = React.createRef();

        this.getResinFactor = getResinFactor.bind(this)
        this.handleResinTypeDropdownChange = this.handleResinTypeDropdownChange.bind(this)


    }

    render() {
        console.log('rendering print')
        const resinType = this.props.dataFromParent.resinType;
        const materialCost = this.props.dataFromParent.materialCost

        return (
            <Col>
                <Row>
                    <Col><h6>Print Number {this.props.printIndex+1}</h6></Col>
                    <Col><Button variant="outline-danger" onClick={this.deletePrint.bind(this)}>Delete Print</Button></Col>
                </Row>

                <Form>
                    <Row>
                        <Col><Form.Label>Resin Type</Form.Label></Col>
                        <Col><Form.Control
                            value={resinType}
                            as="select"
                            onChange={this.handleResinTypeDropdownChange}>
                            <option value="Draft">Draft</option>
                            <option value="Durable">Durable</option>
                            <option value="Elastic">Elastic</option>
                            <option value="Gray">Gray</option>
                            <option value="Tough">Tough</option>
                            <option value="Tough 1500">Tough 1500</option>
                            </Form.Control></Col>
                        <Col><Form.Label>Total Material Cost</Form.Label></Col>
                        <Col><Form.Control
                            readOnly
                            value = {materialCost}
                            id = 'materialCost'
                            /></Col> 
                    </Row>
                    <Row>
                        <Col><Form.Label>Are the resin tank and print environment suitable?
                            <Badge pill variant='dark'>{this.props.dataFromParent.prePrintCheckoff+' '
                            +this.props.dataFromParent.prePrintCheckoffUser+' '
                            +this.props.dataFromParent.prePrintCheckoffDate}</Badge></Form.Label></Col>
                        <Col><ButtonGroup>
                            <Button 
                                variant="secondary"
                                name="prePrintCheckoff-Yes"
                                onClick={this.handleButtonGroupChange.bind(this)}>Yes</Button>
                            <Button 
                                variant="secondary"
                                name="prePrintCheckoff-No"
                                onClick={this.handleButtonGroupChange.bind(this)}>No</Button>
                            </ButtonGroup></Col>
                    </Row>
                    <Row>
                        <Col><Form.Label>Are there no visible lines, surface impurities, or layer slippage?
                            <Badge pill variant='dark'>{this.props.dataFromParent.postPrintCheckoff+' '
                            +this.props.dataFromParent.postPrintCheckoffUser+' '
                            +this.props.dataFromParent.postPrintCheckoffDate}</Badge></Form.Label></Col>
                        <Col><ButtonGroup>
                            <Button 
                                variant="secondary"
                                name="postPrintCheckoff-Yes"
                                onClick={this.handleButtonGroupChange.bind(this)}>Yes</Button>
                            <Button 
                                variant="secondary"
                                name="postPrintCheckoff-No"
                                onClick={this.handleButtonGroupChange.bind(this)}>No</Button>
                            </ButtonGroup></Col>
                    </Row>
                    <Row>
                        <Col><Form.Label>Are surfaces and edges smooth, and is foam glued evenly?
                            <Badge pill variant='dark'>{this.props.dataFromParent.postProcessingCheckoff+' '
                            +this.props.dataFromParent.postProcessingCheckoffUser+' '
                            +this.props.dataFromParent.postProcessingCheckoffDate}</Badge></Form.Label></Col>
                        <Col><ButtonGroup>
                            <Button 
                                variant="secondary"
                                name="postProcessingCheckoff-Yes"
                                onClick={this.handleButtonGroupChange.bind(this)}>Yes</Button>
                            <Button 
                                variant="secondary"
                                name="postProcessingCheckoff-No"
                                onClick={this.handleButtonGroupChange.bind(this)}>No</Button>
                            </ButtonGroup></Col>
                    </Row>
                </Form>

                
            </Col>
        )
    }

    // delete print
    deletePrint() {
        this.props.deletePrint(this.props.printIndex)
    }

    // Handle changes to print info with Redux
    // *** NOT used currently, because no fields required to change in this way, but kept for utility
    newHandleChange(event) {
        event.persist()
        console.log(this.props)
        console.log(this.props.printIndex)

        console.log(event.target.id+' '+event.target.value)  

        this.props.handlePrintChange(event.target.id, event.target.value, this.props.versionIndex, this.props.printIndex)

    }

    // handle changes to print button group
    handleButtonGroupChange = (event) => {

        console.log('handling button group change')
        event.persist()
        const userTimestamp = getUserTimestamp()
        
        const baseID = event.target.name.split('-')[0];
        const result = event.target.name.split('-')[1];
        const versionIndex = this.props.versionIndex;
        const printIndex = this.props.printIndex;

        // TODO: Adjust orderReducer to handle complex actions so we only have to fire one of these

        this.props.handlePrintChange(baseID, result, versionIndex, printIndex)
        this.props.handlePrintChange(baseID+'User', userTimestamp[1], versionIndex, printIndex)
        this.props.handlePrintChange(baseID+'Date', userTimestamp[0], versionIndex, printIndex)
    }

    // handle change to resin type dropdown
    async handleResinTypeDropdownChange(event) {
        event.persist()
        console.log('resin type change triggered')
        console.log(this.props)
        this.props.handlePrintChange('resinType', event.target.value, this.props.versionIndex, this.props.printIndex)

        this.props.updateMaterialCosts(this.props.versionIndex)
        this.props.updateFinancialInfo()
    }
}

// --------------------
// Redux

const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleVersionChange: (id, value, versionIndex) => dispatch(handleVersionChange(id, value, versionIndex)),
        handlePrintChange: (id, value, versionIndex, printIndex) => dispatch(handlePrintChange(id, value, versionIndex, printIndex)),
        updateMaterialCosts: (versionIndex) => dispatch(updateMaterialCosts(versionIndex)),
        updateFinancialInfo: () => dispatch(updateFinancialInfo()),
        deleteDesignVersion: (versionIndex) => dispatch(deleteDesignVersion(versionIndex)),
        addNewPrint: (versionIndex) => dispatch(addNewPrint(versionIndex)),
        deletePrint: (versionIndex, printIndex) => dispatch(deletePrint(versionIndex, printIndex))

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DesignVersion);

var infoCard = {
    display: 'flex',
    flexDirection: 'row'
}
const INITIAL_STATE = {
    clients: [
        {id: 'Duke University'},
        {id: 'NC State University'}
    ],
}

const clientReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'CREATE_CLIENT': {
            console.log('created client', action.client)
            var newClient = action.client;
            return { 
                ...state,
                clients: [...state.clients, action.client]
            }
        }
        case 'GET_CLIENTS': {
            console.log('got clients', JSON.stringify(action.clients))
            let clients = [...state.clients]
            return {
                ...state,
                clients: action.clients
            }
        }
        default:
            return state;
    }
}

export default clientReducer;
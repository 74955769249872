import React from 'react';

import MyToast from '../components/Toast';
import { removeToast } from '../actions/toastActions';
import { connect } from 'react-redux';

let id = 0;

const defaultOptions = {
    color: '#6796e6'
};

export function createToast(options) {

    console.log(options);

    return {
        ...defaultOptions,
        ...options,
        id: id++
    };
};

const Toasts = ({ toasts }) => {

    return (
        <ul className="toasts">
            {toasts.map((toast, index) => {
                const { id } = toast;

                return (
                    <MyToast {...toast} key={id}/>
                )
            })}
        </ul>
    )
}

const mapStateToProps = (state) => {
    return {
        toasts: state.toast.toasts
    }
}

export default connect(mapStateToProps)(Toasts);
const INITIAL_STATE = {
    toasts: []
}

const toastReducer = (state = INITIAL_STATE, action) => {
    // TODO: destructure action when it's received in all reducers

    switch (action.type) {
        case 'ADD_TOAST': {

            console.log(action.payload)
            return {
                ...state,
                toasts: [...state.toasts, action.payload]
            }
        }

        case 'REMOVE_TOAST': {
            console.log(action.payload)
            return {
                ...state,
                toasts: [...state.toasts].filter(toast => {
                    console.log(toast)
                    return toast.id !== action.payload;
                })
                    
            }

        }
        default:
            return state;
    }
}

export default toastReducer;
import { getFirestore } from 'redux-firestore';
import { version } from 'moment';
import { getOrderLocation, generateOrderID } from '../utils/Utils';
import firebase from '../components/Firebase';

import { addToast } from './toastActions';
import { mistyrose } from 'color-name';
import { fail } from 'assert';

const storageRef = firebase.storage().ref()
const dbRef = firebase.firestore()

export const getOrders = (params) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();

        firestore.get({
            collection: 'sectors',
            doc: params.sector,
            subcollections: [
                { collection: 'clients', doc: params.client },
                { collection: 'teams', doc: params.team },
                { collection: 'orders'}
            ],
            orderBy: ['timestamp', 'desc'],
        }).then((snapshot) => {
            dispatch({ type: 'GET_ORDERS', payload: snapshot.docs})
        })
    }
}

export const setCurrentOrder = (uniqueID) => {

    console.log(uniqueID)
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();
        // Get current values of order from Firestore
        console.log(uniqueID)
        firestore.get({
            collectionGroup: 'orders',
            where: ['padUniqueID', '==', uniqueID]
        }).then((snapshot) => {
            console.log(snapshot)
            snapshot.forEach(doc => {
                const updatedOrder = doc.data()

                const location = getOrderLocation(doc.ref.path);
                updatedOrder.client = location.client;
                updatedOrder.sector = location.sector;
                updatedOrder.team = location.team;

                updatedOrder.orderID = generateOrderID(updatedOrder);

                dispatch({ type: 'SET_CURRENT_ORDER', updatedOrder})
            })
            //console.log(updatedOrder.docs[0].data)
            //dispatch({ type: 'START_SET_CURRENT_ORDER', updatedOrder })
        }).catch((err) => {
            console.log('Error setting current order', err)
        })
    }
}

export const setOrderListener = (orderID) => {
    console.log(orderID)
    return (dispatch) => {
        dispatch({ type: 'START_SET_ORDER_LISTENER', orderID})
    }
}

export const handleOrderDetailChange = (id, value, scope, index) => {
    // id is the event id, value is event value, and scope is at which level of order detail change is happening
    const payload = {id, value, scope, index}
    return (dispatch, getState) => {
        // Save new value to store based on event.id and event.value
        dispatch({ type: 'HANDLE_ORDER_DETAIL_CHANGE', payload})
    }
}

export const handleVersionChange = (id, value, versionIndex) => {
    const payload = {id, value, versionIndex}
    return (dispatch, getState) => {
        dispatch({ type: 'HANDLE_VERSION_CHANGE', payload})
    }
}

export const handlePrintChange = (id, value, versionIndex, printIndex) => {
    const payload = {id, value, versionIndex, printIndex}
    return (dispatch, getState) => {
        dispatch({ type: 'HANDLE_PRINT_CHANGE', payload})
    }
}

export const updateMaterialCosts = (versionIndex) => {
    return (dispatch, getState) => {
        dispatch({ type: 'UPDATE_MATERIAL_COSTS', versionIndex })
    }
}

export const updateFinancialInfo = () => {
    return (dispatch, getState) => {
        dispatch({ type: 'UPDATE_FINANCIAL_INFO'})
    }
}

export const addNewDesignVersion = () => {
    return (dispatch, getState) => {
        dispatch({ type: 'ADD_NEW_DESIGN_VERSION'})
    }
}

export const deleteDesignVersion = (versionIndex) => {
    return (dispatch, getState) => {
        dispatch({ type: 'DELETE_DESIGN_VERSION', versionIndex})
    }
}

export const addNewPrint = (versionIndex) => {
    return (dispatch, getState) => {
        dispatch({ type: 'ADD_NEW_PRINT', versionIndex})
    }
}

export const deletePrint = (versionIndex, printIndex) => {
    const payload = {versionIndex, printIndex}
    return (dispatch, getState) => {
        dispatch({ type: 'DELETE_PRINT', payload})
    }
}

export const saveOrderDetail = (curOrder) => {
    console.log(curOrder)
    const payload = {curOrder}
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();

        firestore.update({
            collection: 'sectors',
            doc: curOrder.sector,
            subcollections: [
                { collection: 'clients', doc: curOrder.client },
                { collection: 'teams', doc: curOrder.team },
                { collection: 'orders', doc: curOrder.padUniqueID}]
        }, curOrder).then(() => {
            dispatch({ type: 'SAVE_ORDER_DETAIL'})

            const successToast={
                title: 'Success :)',
                body: 'Changes saved successfully!',
            }
            dispatch(addToast(successToast))

            
        }).catch((err) => {
            console.log('Error saving order detail', err)

            const failureToast={
                title: 'Failure :(',
                body: 'Changes were not saved. Check your internet connection and try again.',
            }
            dispatch(addToast(failureToast))
        })

    }
}

export const uploadRenderFiles = (storagePath, dbPath, uniqueID, files) => {
    const payload = {storagePath, dbPath, uniqueID, files}
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firebase = getFirebase();
        const firestore = getFirestore();

        console.log(payload)

        var fullPath = storagePath+'/'+uniqueID


        files.forEach((file, index) => {
            if (file != undefined) {
                console.log(file)

                var key;

                switch(index) {
                    case 0:
                        key = 'designProductLink';
                        Object.defineProperty(file, 'name', {
                            writable: true,
                            value: uniqueID+'-productRender.obj'
                        })
                        break;
                    case 1:
                        key = 'designScanLink';
                        Object.defineProperty(file, 'name', {
                            writable: true,
                            value: uniqueID+'-scanRender.obj'
                        })
                        break;
                    case 2:
                        key = 'designTextLink';
                        Object.defineProperty(file, 'name', {
                            writable: true,
                            value: uniqueID+'-textRender.obj'
                        })
                        break;
                    default:
                        break;
                }

                var fileName = uniqueID+'-'+key


                firebase
                    .uploadFile(fullPath, file)
                    .then((res) => {
                        return res.uploadTaskSnapshot.ref.getDownloadURL();
                    })
                    .then(downloadURL => {
                        console.log(`Successfully uploaded file - ${downloadURL}`)
                        return downloadURL
                    })
                    .catch(error => {
                        console.log(`Failed to upload file - ${error}`)
                        const failureToast={
                            title: 'Upload Failed',
                            body: 'File upload failed. Please check internet connection and try again.',
                        }
                        dispatch(addToast(failureToast))
                    })
                    .then(downloadURL => {
                        
                        console.log('link: ', downloadURL)

                        // add to store
                        let id = key
                        let value = downloadURL
                        let scope
                        let index
                        // id is the event id, value is event value, and scope is at which level of order detail change is happening
                        const payload = {id, value, scope, index}

                        dispatch({ type: 'HANDLE_ORDER_DETAIL_CHANGE', payload})
    

                        // update in Firestore
                        firestore.update({
                            collection: 'sectors',
                            doc: storagePath.split('/')[0],
                            subcollections: [
                                { collection: 'clients', doc: storagePath.split('/')[1]},
                                { collection: 'teams', doc: storagePath.split('/')[2]},
                                { collection: 'orders', doc: uniqueID}]
                        }, {
                            [key]: downloadURL
                        })
                        .then(() => {
                            console.log('doc updated successfully')

                            const successToast={
                                title: 'Upload Success',
                                body: 'Files were successfully uploaded and saved to Firestore!',
                            }
                            dispatch(addToast(successToast))
                        })
                        .catch((e) => {
                            console.log('update failed with: ', e)
                            const failureToast={
                                title: 'Firestore Save Failed',
                                body: 'Files were successfully uploaded, but database links were not. Please try again.',
                            }
                            dispatch(addToast(failureToast))
                        })
                    })
            }
            else {
                console.log('no file to upload')
            }
        })
        // storagePath = 

        // firebase
        //     .uploadFile(storagePath, )

        // set proper file names
        // add files to firebase
        // update order doc with file links
    }
}

import React from 'react';
import firebase from 'firebase';
import { appendToMemberExpression } from '@babel/types';
import 'bootstrap/dist/css/bootstrap.min.css';
import {ListGroup, Col, Row, Card, Button, Container, InputGroup, FormControl, DropdownButton, Dropdown, Spinner} from 'react-bootstrap';
import Order from './Order';
import logo from '../../assets/fullLogo.png';
import { BsThreeDots } from 'react-icons/bs';

import {getOrdersFromQuery, getClients} from '../Firebase';

import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

import { getOrders } from '../../actions/orderActions';


const db = firebase.firestore();

class Orders extends React.Component{

    constructor(props) {
        super(props);

        var pageTitle = "Orders";

        this.state = {
            orders: [],
            clients: [],
            teams: [],
            selectedClient: "Select Client",
            selectedTeam: "Select Team"

        };

        this.getOrdersFromQuery = getOrdersFromQuery.bind(this)
        this.getClients = getClients.bind(this)
        // this.getOrders = this.getOrders.bind(this)

        let ordersUnsubRef;
        let clientsUnsubRef;

        //this.getOrders = this.getOrders.bind(this);

        this.mClient = React.createRef();
        this.mTeam = React.createRef();        
    }

    stopListener = (unsubRef) => {
        unsubRef();
    }


    componentDidMount() {

        // get clients
        this.clientsUnsubRef = this.getClients();
        console.log(this.clientsUnsubRef)
        this.handleReturnFromDetail()

    }

    // componentWillUnmount() {
    //     this.stopListener(this.ordersUnsubRef);
    //     this.stopListener(this.clientsUnsubRef);
    // }

    sendClientInfoToParent = (client, team) => {    
        console.log(client, team)
        this.props.sendInfoToApp(client, team)
    }

    async handleReturnFromDetail() {
        var isLoadingFromData = this.props.isLoadingFromData;

        if(isLoadingFromData) {

            console.log('returned from detail, setting state')
            await this.setState({
                selectedClient: this.props.savedClient,
                selectedTeam: this.props.savedTeam
            })
            this.props.resetDetailTracker()

            this.getOrders()

            console.log('immediately after resetting detail in orders')
        }
    }

    render(){

        console.log(this.props)
        // if (this.props.isLoadingFromData) {
        //     this.setState({
        //         selectedClient: this.props.savedClient,
        //         selectedTeam: this.props.savedTeam
        //     })
        //     this.props.resetDetailTracker()
            
        // }

        let sortedOrders = [];
        let listItems = [];

        if (this.props.orders) {
            sortedOrders = this.props.orders;
            // mySort(this.state.orders, 'name');
            listItems = sortedOrders.map((d) => <Order testData = 'hi' dataFromParent = {d} saveClientInfo={this.sendClientInfoToParent}/>);
        } else {
            
        }

        

        console.log(this.state.clients);

        console.log(this.state)
        console.log('about to return from render')

        return (
            <div>
                <Container>
                    <Row>
                        <Col>
                            <h1 className="text-center">Orders</h1>
                        </Col>

                    </Row>
                </Container>

                <Container>

                    <InputGroup className="mb-3">
                        <DropdownButton
                            as={InputGroup.Prepend}
                            variant="outline-secondary"
                            title={this.state.selectedClient}
                            id="input-client-dropdown"
                            ref={this.mClient}
                            onSelect={eventKey => this.handleClientDropdownChange(eventKey)}>
                            {this.state.clients.map((client) => <Dropdown.Item eventKey={client.id} value={client.id}>{client.id}</Dropdown.Item>)}
                        </DropdownButton>

                        <DropdownButton
                            as={InputGroup.Prepend}
                            variant="outline-secondary"
                            title={this.state.selectedTeam}
                            id="input-client-dropdown"
                            ref={this.mTeam}
                            onSelect={eventKey => this.handleTeamDropdownChange(eventKey)}>
                            {this.state.teams.map((team) => <Dropdown.Item eventKey={team.id} value={team.id}>{team.id}</Dropdown.Item>)}
                        </DropdownButton>
                    </InputGroup>

                    
                    <Button variant="primary" onClick={this.newGetOrders}>Get Orders</Button>
                </Container>

                <div style={orderTable} >
                    <Card style={headerBar}>
                        <Row>
                            <Col>
                                <Button onClick={mySort(sortedOrders,'name')} style={menuButton}>Name</Button>
                            </Col>
                            <Col>
                                <Button onClick={mySort(sortedOrders,'team')} style={menuButton}>Team</Button>
                            </Col>
                            <Col>
                                <Button onClick={mySort(sortedOrders,'position')} style={menuButton}>Position</Button>
                            </Col>
                            <Col>
                                <Button onClick={mySort(sortedOrders,'number')} style={menuButton}>Number</Button>
                            </Col>
                            <Col>
                                <Button onClick={mySort(sortedOrders,'padType')} style={menuButton}>Device Type</Button>
                            </Col>
                            <Col>
                                <Button style={menuButton}>Order Status</Button>
                            </Col>
                            <Col>
                                <Button style={menuButton}>Internal Status</Button>
                            </Col>
                            <Col>Download Scan</Col>
                            <Col>Order Detail</Col>
                        </Row>
                    </Card>
                    <ListGroup>{listItems}</ListGroup>
                </div>
            </div>
        );
    }

    newGetOrders = () => {

        const params = {
            sector: 'athletics',
            client: this.state.selectedClient,
            team: this.state.selectedTeam
        }
        this.props.getOrders(params)
    }

    // async getOrders(event) {

    //     console.log('getting orders')
    //     this.ordersUnsubRef = this.getOrdersFromQuery();
    //     console.log(this.state.orders);
    
    // }

    async handleClientDropdownChange(eventKey) {
        console.log('change triggered in client dropdown')
        console.log(eventKey);

        this.setState({ 
            selectedClient: eventKey
        });

        db.collection('sectors').doc('athletics').collection('clients').doc(eventKey).collection('teams').get()
        .then(querySnapshot => {
            const teams = [];

            querySnapshot.forEach(function(doc) {

                console.log(doc.id);

                teams.push({
                    id: doc.id
                });
            });
            this.setState({teams});
        })
        .catch(err => {
            console.log('Error getting teams', err);
        });

        console.log(this.state.teams);
    }

    async handleTeamDropdownChange(eventKey) {
        console.log('change triggered in team dropdown')
        console.log(eventKey);

        this.setState({
            selectedTeam: eventKey
        });
    }

}


function mySort(arr, key){
    arr.sort((a,b) => (a[key] > b[key]) ? 1 : -1);
}

const handleSelect = (e) => {
    console.log('change triggered in dropdown')
    console.log(e);
}

const mapStateToProps = (state) => {
    return {
        sectors: state.firestore.ordered.sectors,
        orders: state.order.orders
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getOrders: (params) => dispatch(getOrders(params)),
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(() => [
        {
            collection: 'sectors',
            doc: 'athletics',
            subcollections: [
                { collection: 'clients'}
            ]
        }
    ])
)(Orders);

var orderTable = {
    padding: '40px',
    backgroundColor: 'white',

};

var headerBar = {
    padding: '10px',
    fontFamily: 'Roboto',
    fontSize: '20px'
}

var menuButton = {
    fontSize: '20px',
    color: 'black',
    backgroundColor: 'white',
    borderColor: 'lightgray',
    borderStyle: 'solid'
}


import React from 'react';
import firebase from '../Firebase';
import Navbar from '../Navbar';
import withFirebaseAuth from 'react-with-firebase-auth';
import {BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Sidenav, {Toggle, Nav, NavItem, NavIcon, NavText} from '@trendmicro/react-sidenav';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css'
//import DatePicker from 'react-bootstrap-date-picker';


import './App.css';


import Orders from '../Orders/Orders';
import SignIn from '../SignIn/SignIn';
import SignUp from '../SignUp/SignUp';
import Manage from '../Manage';
import Header from '../layout/Header';
import Landing from '../Landing';
import Navigation from '../Navbar';
import Home from '../Home';
import Detail from '../Detail';
import Clients from '../Clients/Clients';
import ClientDetail from '../Clients/ClientDetail';
import OrderDetail from '../Orders/OrderDetail';
import Toasts from '../../utils/Toasts';

import PrivateRoute from '../../utils/PrivateRoute';
import { app } from 'firebase';
import { CardBody } from 'react-bootstrap/Card';

const firebaseAppAuth = firebase.auth();

const providers ={
  googleProvider: new firebase.auth.GoogleAuthProvider(),
};

const db = firebase.firestore();

//var DatePicker = require('react-bootstrap-date-picker')

class App extends React.Component {

  state = {
    loading: true, 
    authenticated: false, 
    user: null,
    currentClient: null,
    currentTeam: null,
    isLoadingFromData: false
  };

  getClientTeamFromOrders = (client, team) => {
    console.log('setting state in App.js')
    this.setState({
      currentClient: client,
      currentTeam: team,
      isLoadingFromData: true
    })
    console.log(this.state.currentClient)
    console.log(this.state.currentTeam)

  }

  resetDetailBoolean = () => {
    console.log('resetting Detail tracker in App.js')

    this.setState({
      isLoadingFromData: !this.state.isLoadingFromData,
    })
  }

  render() {

    //console.log(this.state.currentClient)
    //console.log(this.state.currentTeam)

    const {authenticated, loading} = this.state;

    if (loading) {
      return <Navbar/>;
    }

    if (!authenticated) {
      return <Landing/>;
    }

    return (
      <Router basename="/">
        <div id="root">
          <Navbar/>
          <Toasts/>
          <Switch>
            <Route path='/' component={Home} exact />
            <PrivateRoute 
              path='/orders' 
              component={Orders} 
              authenticated={authenticated}
              resetDetailTracker = {this.resetDetailBoolean}
              sendInfoToApp={this.getClientTeamFromOrders}
              savedClient = {this.state.currentClient}
              savedTeam = {this.state.currentTeam}
              isLoadingFromData = {this.state.isLoadingFromData}
              exact/>
            <Route path='/home' component={Home} exact/>
            <Route path='/manage' component={Manage} exact/>
            <Route path='/detail' component={Detail} exact/>
            <Route path='/order' component={OrderDetail} exact/>
            <Route path='/client' component={ClientDetail} exact/>
            <Route path='/clients' component={Clients} exact/>
          </Switch>
        </div>
      </Router>
    );
  }

  componentDidMount() {
    firebaseAppAuth.onAuthStateChanged(user => {
      if (user) {
        this.setState({
          authenticated: true,
          currentUser: user,
          loading: false
        });
      } else {
        this.setState({
          authenticated: false,
          currentUser: null,
          loading: false
        });
      }
    });
  }
}

export default withFirebaseAuth({
  providers,
  firebaseAppAuth,
})(App);

import { createToast } from '../utils/Toasts';

export const addToast = (options) => {

    // console.log(options)

    // const newToast = createToast(options);
    
    // const payload = newToast;

    
    return (dispatch) => {
        dispatch({ type: 'ADD_TOAST', payload: createToast(options) })
    }
}

export const removeToast = (id) => {

    console.log(id)
    return (dispatch) => {
        dispatch({ type: 'REMOVE_TOAST', payload: id })
    }
}